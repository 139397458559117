export default [
  {
    pageTitle: 'pages.calendar.title',
    path: '/pages/calendar',
    name: 'calendar',
    component: () => import('@/views/pages/calendar/Calendar.vue'),
    // children: [
    //   {
    //     path: '',
    //     name: 'calendar',
    //     component: () => import('@/views/pages/category/ListCategoriesPage.vue'),
    //     meta: {
    //       pageTitle: 'pages.category.title',
    //       breadcrumb: [
    //         {
    //           text: 'pages.category.title',
    //           active: true,
    //         },
    //       ],
    //     },
    //   },
    // ],
  },
]

export default [
  {
    path: '/pages/customer',
    component: () => import('@/views/pages/prospect/IndexPage.vue'),
    children: [
      {
        path: 'prospects',
        name: 'list-prospects',
        component: () => import('@/views/pages/prospect/ListProspectsPage.vue'),
        meta: {
          pageTitle: 'pages.prospect.title',
          breadcrumb: [
            {
              text: 'pages.prospect.title',
              active: true,
            },
          ],
        },
      },
      {
        path: 'prospects-inactive',
        name: 'list-prospects-inactive',
        component: () => import('@/views/pages/prospect/ListProspectsInActivePage.vue'),
        meta: {
          pageTitle: 'pages.prospect.title-inactive',
          breadcrumb: [
            {
              text: 'pages.prospect.title-inactive',
              active: true,
            },
          ],
        },
      },
      {
        path: 'customers-waiting',
        name: 'list-customers-waiting',
        component: () => import('@/views/pages/customer/ListCustomersWaitingPage.vue'),
        meta: {
          pageTitle: 'pages.customer.title-waiting',
          breadcrumb: [
            {
              text: 'pages.customer.title-waiting',
              active: true,
            },
          ],
        },
      },
      {
        path: 'validated',
        name: 'list-customers-validated',
        component: () => import('@/views/pages/customer/ListCustomersValidatedPage.vue'),
        meta: {
          pageTitle: 'pages.customer.validated',
          breadcrumb: [
            {
              text: 'pages.customer.validated',
              active: true,
            },
          ],
        },
      },
      {
        path: 'legal',
        name: 'list-customers-legal',
        component: () => import('@/views/pages/customer/ListCustomersLegalPage.vue'),
        meta: {
          pageTitle: 'pages.customer.legal',
          breadcrumb: [
            {
              text: 'pages.customer.legal',
              active: true,
            },
          ],
        },
      },
      {
        path: 'accounting',
        name: 'list-customers-accounting',
        component: () => import('@/views/pages/customer/ListCustomersAccountingPage.vue'),
        meta: {
          pageTitle: 'pages.customer.accounting',
          breadcrumb: [
            {
              text: 'pages.customer.accounting',
              active: true,
            },
          ],
        },
      },
      {
        path: 'prospect-details',
        name: 'prospect-details',
        component: () => import('@/views/pages/prospect/ProspectDetailsPage.vue'),
        meta: {
          pageTitle: 'pages.prospect.details',
          breadcrumb: [
            {
              text: 'pages.prospect.details',
              active: true,
            },
          ],
        },
      },
      {
        path: 'customer-details',
        name: 'customer-details',
        component: () => import('@/views/pages/customer/CustomerDetailsPage.vue'),
        meta: {
          pageTitle: 'pages.customer.details',
          breadcrumb: [
            {
              text: 'pages.customer.details',
              active: true,
            },
          ],
        },
      },
    ],
  },
  {
    path: '/pages/package',
    component: () => import('@/views/pages/package/IndexPage.vue'),
    children: [
      {
        path: 'list',
        name: 'list-packages',
        component: () => import('@/views/pages/package/ListPackagesPage.vue'),
        meta: {
          pageTitle: 'pages.package.title',
          breadcrumb: [
            {
              text: 'pages.package.title',
              active: true,
            },
          ],
        },
      },
      {
        path: 'details',
        name: 'package-details',
        component: () => import('@/views/pages/package/PackageDetailsPage.vue'),
        meta: {
          pageTitle: 'pages.package.title',
          breadcrumb: [
            {
              text: 'pages.package.title',
              active: true,
            },
          ],
        },
      },
    ],
  },
  
]

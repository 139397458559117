import { Ability } from '@casl/ability'
import awsconfig from '@/aws-exports';
import { initialAbility } from './config'
//  Read ability from localStorage
// * Handles auto fetching previous abilities if already logged in user
// ? You can update this if you store user abilities to more secure place
// ! Anyone can update localStorage so be careful and please update this
const userData = localStorage.getItem(`CognitoIdentityServiceProvider.${awsconfig.aws_user_pools_web_client_id}.LastAuthUser`)
const permission = [{ action: 'manage', subject: 'all' }];
const existingAbility = userData ? permission : null
export default new Ability(existingAbility || initialAbility)

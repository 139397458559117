/* eslint-disable*/
import { API } from 'aws-amplify';
import * as mutations from '@/graphql/mutations';
import { find, isArray as _isArray } from 'lodash';
export default {
  async uploadImageCloudinary(imgUrl, oldPublicId) {
    return API.graphql({
      query: mutations.uploadImageCloudinary,
      variables: { imgUrl, oldPublicId },
      authMode: 'AMAZON_COGNITO_USER_POOLS',
    })
  },
  getCloudinarySecureUrlByStr(cloudinaryDataStr, key) {
    const cloudinaryObj = this.getCloudinaryObj(cloudinaryDataStr, key);
    if (!cloudinaryObj) return null;
    return cloudinaryObj.secure_url;
  },
  getCloudinaryPublicIdByStr(cloudinaryDataStr, key) {
    const cloudinaryObj = this.getCloudinaryObj(cloudinaryDataStr, key);
    if (!cloudinaryObj) return null;
    return cloudinaryObj.public_id;
  },
  isArray(value) {
    return value && _isArray(value);
  },
  getCloudinaryObj(cloudinaryDataStr, key) {
    if (!cloudinaryDataStr || !key) return null;
    const objCloudinary = JSON.parse(cloudinaryDataStr);
    if (this.isArray(objCloudinary)) {
      const exists = find(objCloudinary, (x) => x.key === key);
      if (exists) return exists.value;
    } else {
      // eslint-disable-next-line quotes
      if (objCloudinary && objCloudinary["key"] === key) {
        return objCloudinary.value;
      }
      return null;
    }
    return null;
  },
  getListTagInText(content, tagName = null) {
    // eslint-disable-next-line prefer-const
    let result = [];
    let tempContent = content;
    if (!tempContent) return [];
    while (tempContent) {
      let imgTabEndIndex = -1;
      let workingText = '';
      let imgTabStartIndex = tempContent.indexOf(tagName ? `<${tagName}` : '<img');
      if (imgTabStartIndex === -1) imgTabStartIndex = tempContent.indexOf(tagName ? `< ${tagName}` : '< img');
      if (imgTabStartIndex === -1) tempContent = '';
      else {
        tempContent = tempContent.substring(imgTabStartIndex, tempContent.length);
        if (!tagName || tagName === 'img') {
          imgTabEndIndex = tempContent.indexOf('/>');
          if (imgTabEndIndex !== -1) imgTabEndIndex += 2;
        } else {
          let checkEnd1 = tempContent.indexOf('>');
          let checkEnd2 = tempContent.indexOf('/>');
          let checkEnd3 = tempContent.indexOf(`</${tagName}`);
          let sorts = [];
          if (checkEnd1 !== -1) { 
            checkEnd1 += 2;
            sorts.push(checkEnd1);
          }
          if (checkEnd2 !== -1) {
            checkEnd2 += 3;
            sorts.push(checkEnd2);
          }
          if (checkEnd3 !== -1) {
            checkEnd3 = checkEnd3 + 4 + tagName.length;
            sorts.push(checkEnd3);
          }
          sorts = sorts.sort();
          // eslint-disable-next-line prefer-destructuring
          imgTabEndIndex = sorts[0];
        }
        workingText = tempContent.substring(0, imgTabEndIndex);
        result.push(workingText);
        // remove working test had done
        tempContent = tempContent.substring(imgTabEndIndex, tempContent.length);
      }
    }
    return result;
  },
  getPropValueInTag(content, propName, tagName = null) {
    let result = null;
    let tempContent = content;
    if (!tempContent) return "";
      let imgTabEndIndex = -1;
      let workingText = "";
      let imgTabStartIndex = tempContent.indexOf(tagName ? `<${tagName}` : "<img");
      if (imgTabStartIndex === -1)
        imgTabStartIndex = tempContent.indexOf(tagName ? `< ${tagName}` : "< img");
      if (imgTabStartIndex === -1) tempContent = "";
      else {
        tempContent = tempContent.substring(imgTabStartIndex, tempContent.length);
        imgTabEndIndex = tempContent.indexOf("/>");
        if (imgTabEndIndex !== -1) imgTabEndIndex += 2;
        workingText = tempContent.substring(0, imgTabEndIndex);
        let arrSrc = [];
        arrSrc = workingText.split(propName);
        if (arrSrc.length > 1) {
          let srcText = arrSrc[1];
          srcText = srcText.trim();
          srcText = srcText.replace("='", "");
          srcText = srcText.replace('="', "");
          srcText = srcText.trim();
          const index = srcText.indexOf('"');
          srcText = srcText.substring(0, index);
          srcText = srcText.replace("'", "");
          srcText = srcText.replace('"', "");
          srcText = srcText.trim();
          result = srcText;
        }
      }
    return result;
  }
}

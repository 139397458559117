import awsconfig from '@/aws-exports';
import commonServices from '@/services/commonServices';
import userServices from '@/services/userServices';
import { Auth } from 'aws-amplify';
import router from '@/router'
export default {
  namespaced: true,
  state: {
    profile: null,
    isAuthenticated: !!localStorage.getItem(`CognitoIdentityServiceProvider.${awsconfig.aws_user_pools_web_client_id}.LastAuthUser`),
    roles: [],
  },
  getters: {
    hasRole: (state) => (roleName) => {
      if (state.roles && state.roles.length > 0 && state.roles.indexOf(roleName) >= 0) {
        return true
      }
      return false
    },
    getProfile: (state) => state.profile,
  },
  mutations: {
    signInSuccess(state, data) {
      const { next, result } = data;
      if (result.code === undefined) {
        const checkAccess = result.signInUserSession.idToken.payload['cognito:groups'];
        if (checkAccess && checkAccess.includes('Administrators')) {
          state.profile = result.attributes
          state.isAuthenticated = true
          if (next){
            router.go();
          }
          return
        }
        this.signOut();
        commonServices.showError('DeniedAccess')
      }
    },
    async signOut(state) {
      state.profile = null
      state.isAuthenticated = false
      state.roles = []
      await Auth.signOut()
      router.push({ name: 'login' })
    },
  },
  actions: {
    async verifyUserCurrent({ commit }) {
      const result = await Auth.currentAuthenticatedUser();
      if (result) {
        commit('signInSuccess', {
          result,
          next: null,
        })
      }
    },
    async signIn({ commit }, {
      context, email, password, next,
    }) {
      console.log(next);
      let result = await userServices.signIn(email, password);
      // console.log(result.signInUserSession.accessToken.jwtToken)
      if (result.challengeName === 'NEW_PASSWORD_REQUIRED') {
        result = await Auth.completeNewPassword(
          result,
          password,
        )
      }
      if (result.code !== undefined) {
        commonServices.showMsgCode(context, false, result.code)
        return
      }
      if (result.code === 'UserNotConfirmedException') {
        router.push({
          name: 'Confirm',
          query: { email: this.email, reconfirm: true },
        });
      }
      commit('signInSuccess', {
        result,
        next: '/',
      })
    },
    async signOut({ commit }) {
      commit('signOut')
    },
  },
}

import Vue from 'vue'
import { ToastPlugin, ModalPlugin } from 'bootstrap-vue'
import VueCompositionAPI from '@vue/composition-api'
import Amplify, * as AmplifyModules from 'aws-amplify'
import awsconfig from '@/aws-exports'
// import CKEditor from 'ckeditor4-vue';
import Loading from 'vue-loading-overlay';
import VueQuillEditor from 'vue-quill-editor'
import router from './router'
import store from './store'
import App from './App.vue'
import i18n from './libs/i18n'
// Import component
// Import stylesheet
import 'vue-loading-overlay/dist/vue-loading.css';
// Global Components
import './global-components'

// 3rd party plugins
import '@/libs/portal-vue'
import '@/libs/toastification'
 
// require styles

// Init plugin
Vue.use(Loading);
// BSV Plugin Registration
Vue.use(ToastPlugin)
Vue.use(ModalPlugin)
// Vue.use(CKEditor);   

// Composition API
Vue.use(VueCompositionAPI)
 
Vue.use(VueQuillEditor)
// import core styles
require('@core/scss/core.scss')

// import assets styles
require('@/assets/scss/style.scss')

Vue.config.productionTip = false
Amplify.configure(awsconfig)
Vue.use(AmplifyModules)
new Vue({
  router,
  store,
  i18n,
  render: (h) => h(App),
}).$mount('#app')

/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const register = /* GraphQL */ `
  mutation Register(
    $phone_number: String
    $fullname: String
    $email: String!
    $packageID: ID
    $companyName: String
  ) {
    register(
      phone_number: $phone_number
      fullname: $fullname
      email: $email
      packageID: $packageID
      companyName: $companyName
    )
  }
`;
export const trackingProspectOld = /* GraphQL */ `
  mutation TrackingProspectOld(
    $id: ID!
    $step: ProcessStatusCode!
    $company_already_created: Boolean
    $packageID: ID
    $token: String
    $fullname: String
  ) {
    trackingProspectOld(
      id: $id
      step: $step
      company_already_created: $company_already_created
      packageID: $packageID
      token: $token
      fullname: $fullname
    )
  }
`;
export const trackingProspectNew = /* GraphQL */ `
  mutation TrackingProspectNew(
    $id: ID!
    $step: ProcessStatusCode!
    $company_already_created: Boolean
    $packageID: ID
    $token: String
    $fullname: String
    $paymentMethodId: String
    $signatureDate: String
    $companyName: String
    $sigle: String
    $legalStatus: String
    $object: String
    $capital: String
    $capitalMini: String
    $capitalInitial: String
    $duration: String
    $greffe: String
    $civility: String
    $name: String
    $firstName: String
    $address: String
    $additionalAddress: String
    $zipCode: String
    $city: String
    $meetingAndVoteType: String
    $meetingAndVoteDesc: String
    $clausesDagrements: String
    $clausesDagrementsDesc: String
    $informationManagers: AWSJSON
    $options: AWSJSON
  ) {
    trackingProspectNew(
      id: $id
      step: $step
      company_already_created: $company_already_created
      packageID: $packageID
      token: $token
      fullname: $fullname
      paymentMethodId: $paymentMethodId
      signatureDate: $signatureDate
      companyName: $companyName
      sigle: $sigle
      legalStatus: $legalStatus
      object: $object
      capital: $capital
      capitalMini: $capitalMini
      capitalInitial: $capitalInitial
      duration: $duration
      greffe: $greffe
      civility: $civility
      name: $name
      firstName: $firstName
      address: $address
      additionalAddress: $additionalAddress
      zipCode: $zipCode
      city: $city
      meetingAndVoteType: $meetingAndVoteType
      meetingAndVoteDesc: $meetingAndVoteDesc
      clausesDagrements: $clausesDagrements
      clausesDagrementsDesc: $clausesDagrementsDesc
      informationManagers: $informationManagers
      options: $options
    )
  }
`;
export const confirm3dSecure = /* GraphQL */ `
  mutation Confirm3dSecure(
    $id: ID!
    $key: String
    $paymentMethodId: String
    $paymentIntentId: String
  ) {
    confirm3dSecure(
      id: $id
      key: $key
      paymentMethodId: $paymentMethodId
      paymentIntentId: $paymentIntentId
    )
  }
`;
export const verifyProspect = /* GraphQL */ `
  mutation VerifyProspect(
    $id: ID!
    $verified: Boolean
    $isAutoProcess: Boolean
  ) {
    verifyProspect(id: $id, verified: $verified, isAutoProcess: $isAutoProcess)
  }
`;
export const appointmentProspect = /* GraphQL */ `
  mutation AppointmentProspect(
    $appointmentSummary: String
    $appointmentDescription: String
    $location: String
    $guests: String
    $startDate: String!
    $endDate: String!
    $prospectID: ID!
  ) {
    appointmentProspect(
      appointmentSummary: $appointmentSummary
      appointmentDescription: $appointmentDescription
      location: $location
      guests: $guests
      startDate: $startDate
      endDate: $endDate
      prospectID: $prospectID
    )
  }
`;
export const updateAppointmentProspect = /* GraphQL */ `
  mutation UpdateAppointmentProspect(
    $id: ID!
    $eventId: String!
    $appointmentSummary: String
    $appointmentDescription: String
    $location: String
    $guests: String
    $startDate: String!
    $endDate: String!
    $prospectID: ID!
  ) {
    updateAppointmentProspect(
      id: $id
      eventId: $eventId
      appointmentSummary: $appointmentSummary
      appointmentDescription: $appointmentDescription
      location: $location
      guests: $guests
      startDate: $startDate
      endDate: $endDate
      prospectID: $prospectID
    )
  }
`;
export const deleteAppointmentProspect = /* GraphQL */ `
  mutation DeleteAppointmentProspect($id: ID!, $eventId: String!) {
    deleteAppointmentProspect(id: $id, eventId: $eventId)
  }
`;
export const uploadImageCloudinary = /* GraphQL */ `
  mutation UploadImageCloudinary($imgUrl: String!, $oldPublicId: String) {
    uploadImageCloudinary(imgUrl: $imgUrl, oldPublicId: $oldPublicId)
  }
`;
export const destroyImageCloudinary = /* GraphQL */ `
  mutation DestroyImageCloudinary($oldPublicId: String) {
    destroyImageCloudinary(oldPublicId: $oldPublicId)
  }
`;
export const extendTrialOneMonthContract = /* GraphQL */ `
  mutation ExtendTrialOneMonthContract($contractID: ID) {
    extendTrialOneMonthContract(contractID: $contractID)
  }
`;
export const extendContract = /* GraphQL */ `
  mutation ExtendContract($contractID: ID, $toDate: String!) {
    extendContract(contractID: $contractID, toDate: $toDate)
  }
`;
export const login = /* GraphQL */ `
  mutation Login($email: String!, $password: String!) {
    login(email: $email, password: $password)
  }
`;
export const forgotPassword = /* GraphQL */ `
  mutation ForgotPassword($email: String!) {
    forgotPassword(email: $email)
  }
`;
export const getUserByEmail = /* GraphQL */ `
  mutation GetUserByEmail($email: String!) {
    getUserByEmail(email: $email)
  }
`;
export const resetPassword = /* GraphQL */ `
  mutation ResetPassword($newPassword: String!, $token: String!) {
    resetPassword(newPassword: $newPassword, token: $token)
  }
`;
export const updateMetaData = /* GraphQL */ `
  mutation UpdateMetaData(
    $id: String!
    $token: String!
    $baliseTitle: String
    $baliseDescription: String
    $type: MetaDataType!
  ) {
    updateMetaData(
      id: $id
      token: $token
      baliseTitle: $baliseTitle
      baliseDescription: $baliseDescription
      type: $type
    )
  }
`;
export const refreshToken = /* GraphQL */ `
  mutation RefreshToken($token: String!) {
    refreshToken(token: $token)
  }
`;
export const getHashPassword = /* GraphQL */ `
  mutation GetHashPassword(
    $username: String!
    $password: String!
    $hashString: String!
  ) {
    getHashPassword(
      username: $username
      password: $password
      hashString: $hashString
    )
  }
`;
export const decryptHashPassword = /* GraphQL */ `
  mutation DecryptHashPassword(
    $username: String!
    $password: String!
    $userPassword: String!
  ) {
    decryptHashPassword(
      username: $username
      password: $password
      userPassword: $userPassword
    )
  }
`;
export const envList = /* GraphQL */ `
  mutation EnvList($name: String) {
    envList(name: $name)
  }
`;
export const deleteContract = /* GraphQL */ `
  mutation DeleteContract(
    $input: DeleteContractInput!
    $condition: ModelContractConditionInput
  ) {
    deleteContract(input: $input, condition: $condition) {
      id
      prospectID
      packageID
      customerID
      postalCode
      city
      isSigned
      isWantMail
      unPaid
      contractNumnber
      companyName
      companyAddress
      companyPhoneNumber
      userID
      asignedUser
      fromDate
      toDate
      extendDate
      hasTrial
      terminateDate
      note
      attachments
      orderID
      createdAt
      updatedAt
      owner
      order {
        id
        prospectID
        packageID
        customerID
        orderDate
        fromDate
        month
        year
        toDate
        taxAmount
        discountAmount
        totalAmount
        currency
        note
        orderStatus
        createdAt
        updatedAt
        owner
      }
      customer {
        id
        prospectID
        email
        phone_number
        address
        numberStreet
        additionalAddress
        zipCode
        city
        company_already_created
        packageID
        payment
        fullname
        statusCode
        verified
        contract
        identityFront
        identityBack
        createdAt
        createdAtTimestamp
        updatedAt
        owner
      }
      package {
        id
        order
        name
        price
        currency
        description
        note
        bestChoice
        button
        features
        buttonLoadMore
        colorButtonLoadMore
        code
        createdAt
        updatedAt
        steps {
          items {
            id
            packageID
            step
            summary
            features
            createdAt
            updatedAt
            package {
              id
              order
              name
              price
              currency
              description
              note
              bestChoice
              button
              features
              buttonLoadMore
              colorButtonLoadMore
              code
              createdAt
              updatedAt
            }
            owner
          }
          nextToken
        }
        options {
          items {
            id
            packageID
            price
            code
            name
            description
            statusCode
            createdAt
            updatedAt
            package {
              id
              order
              name
              price
              currency
              description
              note
              bestChoice
              button
              features
              buttonLoadMore
              colorButtonLoadMore
              code
              createdAt
              updatedAt
            }
            owner
          }
          nextToken
        }
      }
    }
  }
`;
export const deleteOrder = /* GraphQL */ `
  mutation DeleteOrder(
    $input: DeleteOrderInput!
    $condition: ModelOrderConditionInput
  ) {
    deleteOrder(input: $input, condition: $condition) {
      id
      prospectID
      packageID
      customerID
      orderDate
      fromDate
      month
      year
      toDate
      taxAmount
      discountAmount
      totalAmount
      currency
      note
      orderStatus
      createdAt
      updatedAt
      owner
    }
  }
`;
export const deleteInvoice = /* GraphQL */ `
  mutation DeleteInvoice(
    $input: DeleteInvoiceInput!
    $condition: ModelInvoiceConditionInput
  ) {
    deleteInvoice(input: $input, condition: $condition) {
      id
      orderID
      paidAmount
      currency
      month
      year
      isFullPaid
      invoiceDate
      invoiceAddress
      invoiceName
      description
      note
      paymentMethod
      customerStripeID
      invoiceStatus
      createdAt
      updatedAt
      order {
        id
        prospectID
        packageID
        customerID
        orderDate
        fromDate
        month
        year
        toDate
        taxAmount
        discountAmount
        totalAmount
        currency
        note
        orderStatus
        createdAt
        updatedAt
        owner
      }
      owner
    }
  }
`;
export const deleteCustomer = /* GraphQL */ `
  mutation DeleteCustomer(
    $input: DeleteCustomerInput!
    $condition: ModelCustomerConditionInput
  ) {
    deleteCustomer(input: $input, condition: $condition) {
      id
      prospectID
      email
      phone_number
      address
      numberStreet
      additionalAddress
      zipCode
      city
      company_already_created
      packageID
      payment
      fullname
      statusCode
      verified
      contract
      identityFront
      identityBack
      createdAt
      createdAtTimestamp
      updatedAt
      owner
    }
  }
`;
export const updateCustomerStripe = /* GraphQL */ `
  mutation UpdateCustomerStripe(
    $input: UpdateCustomerStripeInput!
    $condition: ModelCustomerStripeConditionInput
  ) {
    updateCustomerStripe(input: $input, condition: $condition) {
      id
      customerID
      amount
      name
      chargesID
      payment_method
      receipt_url
      PaymentIntentID
      createdAt
      updatedAt
      owner
    }
  }
`;
export const deleteCustomerStripe = /* GraphQL */ `
  mutation DeleteCustomerStripe(
    $input: DeleteCustomerStripeInput!
    $condition: ModelCustomerStripeConditionInput
  ) {
    deleteCustomerStripe(input: $input, condition: $condition) {
      id
      customerID
      amount
      name
      chargesID
      payment_method
      receipt_url
      PaymentIntentID
      createdAt
      updatedAt
      owner
    }
  }
`;
export const deleteProspect = /* GraphQL */ `
  mutation DeleteProspect(
    $input: DeleteProspectInput!
    $condition: ModelProspectConditionInput
  ) {
    deleteProspect(input: $input, condition: $condition) {
      id
      email
      phone_number
      address
      numberStreet
      additionalAddress
      zipCode
      city
      company_already_created
      packageID
      payment
      fullname
      processStatusCode
      statusCode
      emailReminderTimes
      smsReminderTimes
      token
      verified
      createdAt
      createdAtTimestamp
      ProspectUserId
      signatureDate
      companyName
      sigle
      legalStatus
      object
      capital
      capitalMini
      capitalInitial
      duration
      greffe
      civility
      name
      firstName
      meetingAndVoteType
      meetingAndVoteDesc
      clausesDagrements
      clausesDagrementsDesc
      updatedAt
      owner
    }
  }
`;
export const deleteProspectOption = /* GraphQL */ `
  mutation DeleteProspectOption(
    $input: DeleteProspectOptionInput!
    $condition: ModelProspectOptionConditionInput
  ) {
    deleteProspectOption(input: $input, condition: $condition) {
      id
      prospectID
      optionCode
      statusCode
      createdAt
      updatedAt
      owner
    }
  }
`;
export const deletePic = /* GraphQL */ `
  mutation DeletePic(
    $input: DeletePicInput!
    $condition: ModelPicConditionInput
  ) {
    deletePic(input: $input, condition: $condition) {
      id
      civility
      name
      firstName
      address
      additionalAddress
      pays
      zipCode
      city
      prospectID
      code
      denomination
      type
      formeJuridique
      capital
      greffe
      rsc
      level
      createdAt
      updatedAt
      owner
    }
  }
`;
export const createBlog = /* GraphQL */ `
  mutation CreateBlog(
    $input: CreateBlogInput!
    $condition: ModelBlogConditionInput
  ) {
    createBlog(input: $input, condition: $condition) {
      id
      title
      baliseTitle
      description
      keywords
      slug
      image
      statusCode
      createdAt
      updatedAt
    }
  }
`;
export const updateBlog = /* GraphQL */ `
  mutation UpdateBlog(
    $input: UpdateBlogInput!
    $condition: ModelBlogConditionInput
  ) {
    updateBlog(input: $input, condition: $condition) {
      id
      title
      baliseTitle
      description
      keywords
      slug
      image
      statusCode
      createdAt
      updatedAt
    }
  }
`;
export const deleteBlog = /* GraphQL */ `
  mutation DeleteBlog(
    $input: DeleteBlogInput!
    $condition: ModelBlogConditionInput
  ) {
    deleteBlog(input: $input, condition: $condition) {
      id
      title
      baliseTitle
      description
      keywords
      slug
      image
      statusCode
      createdAt
      updatedAt
    }
  }
`;
export const createArticle = /* GraphQL */ `
  mutation CreateArticle(
    $input: CreateArticleInput!
    $condition: ModelArticleConditionInput
  ) {
    createArticle(input: $input, condition: $condition) {
      id
      blogID
      slug
      balise_title
      title
      image
      cover
      description
      keywords
      content
      statusCode
      createdAt
      bottomPopupMessage
      bottomPopupButton
      bottomPopupUrl
      imageAlt
      coverAlt
      imageCloudinary
      coverCloudinary
      contentCloudinary
      score
      order
      duration
      bookmark
      template
      baliseDescription
      bottomPopupEnabled
      simulatorEnabled
      titleSimulator
      descriptionSimulator
      btnSimulator
      urlSimulator
      imageTitle
      coverTitle
      contentBottomImageAlt
      contentBottomImageTitle
      updatedAt
      blog {
        id
        title
        baliseTitle
        description
        keywords
        slug
        image
        statusCode
        createdAt
        updatedAt
      }
      owner
    }
  }
`;
export const updateArticle = /* GraphQL */ `
  mutation UpdateArticle(
    $input: UpdateArticleInput!
    $condition: ModelArticleConditionInput
  ) {
    updateArticle(input: $input, condition: $condition) {
      id
      blogID
      slug
      balise_title
      title
      image
      cover
      description
      keywords
      content
      statusCode
      createdAt
      bottomPopupMessage
      bottomPopupButton
      bottomPopupUrl
      imageAlt
      coverAlt
      imageCloudinary
      coverCloudinary
      contentCloudinary
      score
      order
      duration
      bookmark
      template
      baliseDescription
      bottomPopupEnabled
      simulatorEnabled
      titleSimulator
      descriptionSimulator
      btnSimulator
      urlSimulator
      imageTitle
      coverTitle
      contentBottomImageAlt
      contentBottomImageTitle
      updatedAt
      blog {
        id
        title
        baliseTitle
        description
        keywords
        slug
        image
        statusCode
        createdAt
        updatedAt
      }
      owner
    }
  }
`;
export const deleteArticle = /* GraphQL */ `
  mutation DeleteArticle(
    $input: DeleteArticleInput!
    $condition: ModelArticleConditionInput
  ) {
    deleteArticle(input: $input, condition: $condition) {
      id
      blogID
      slug
      balise_title
      title
      image
      cover
      description
      keywords
      content
      statusCode
      createdAt
      bottomPopupMessage
      bottomPopupButton
      bottomPopupUrl
      imageAlt
      coverAlt
      imageCloudinary
      coverCloudinary
      contentCloudinary
      score
      order
      duration
      bookmark
      template
      baliseDescription
      bottomPopupEnabled
      simulatorEnabled
      titleSimulator
      descriptionSimulator
      btnSimulator
      urlSimulator
      imageTitle
      coverTitle
      contentBottomImageAlt
      contentBottomImageTitle
      updatedAt
      blog {
        id
        title
        baliseTitle
        description
        keywords
        slug
        image
        statusCode
        createdAt
        updatedAt
      }
      owner
    }
  }
`;
export const createArticleRelated = /* GraphQL */ `
  mutation CreateArticleRelated(
    $input: CreateArticleRelatedInput!
    $condition: ModelArticleRelatedConditionInput
  ) {
    createArticleRelated(input: $input, condition: $condition) {
      id
      relatedID
      articleID
      createdAt
      updatedAt
      article {
        id
        blogID
        slug
        balise_title
        title
        image
        cover
        description
        keywords
        content
        statusCode
        createdAt
        bottomPopupMessage
        bottomPopupButton
        bottomPopupUrl
        imageAlt
        coverAlt
        imageCloudinary
        coverCloudinary
        contentCloudinary
        score
        order
        duration
        bookmark
        template
        baliseDescription
        bottomPopupEnabled
        simulatorEnabled
        titleSimulator
        descriptionSimulator
        btnSimulator
        urlSimulator
        imageTitle
        coverTitle
        contentBottomImageAlt
        contentBottomImageTitle
        updatedAt
        blog {
          id
          title
          baliseTitle
          description
          keywords
          slug
          image
          statusCode
          createdAt
          updatedAt
        }
        owner
      }
      owner
    }
  }
`;
export const updateArticleRelated = /* GraphQL */ `
  mutation UpdateArticleRelated(
    $input: UpdateArticleRelatedInput!
    $condition: ModelArticleRelatedConditionInput
  ) {
    updateArticleRelated(input: $input, condition: $condition) {
      id
      relatedID
      articleID
      createdAt
      updatedAt
      article {
        id
        blogID
        slug
        balise_title
        title
        image
        cover
        description
        keywords
        content
        statusCode
        createdAt
        bottomPopupMessage
        bottomPopupButton
        bottomPopupUrl
        imageAlt
        coverAlt
        imageCloudinary
        coverCloudinary
        contentCloudinary
        score
        order
        duration
        bookmark
        template
        baliseDescription
        bottomPopupEnabled
        simulatorEnabled
        titleSimulator
        descriptionSimulator
        btnSimulator
        urlSimulator
        imageTitle
        coverTitle
        contentBottomImageAlt
        contentBottomImageTitle
        updatedAt
        blog {
          id
          title
          baliseTitle
          description
          keywords
          slug
          image
          statusCode
          createdAt
          updatedAt
        }
        owner
      }
      owner
    }
  }
`;
export const deleteArticleRelated = /* GraphQL */ `
  mutation DeleteArticleRelated(
    $input: DeleteArticleRelatedInput!
    $condition: ModelArticleRelatedConditionInput
  ) {
    deleteArticleRelated(input: $input, condition: $condition) {
      id
      relatedID
      articleID
      createdAt
      updatedAt
      article {
        id
        blogID
        slug
        balise_title
        title
        image
        cover
        description
        keywords
        content
        statusCode
        createdAt
        bottomPopupMessage
        bottomPopupButton
        bottomPopupUrl
        imageAlt
        coverAlt
        imageCloudinary
        coverCloudinary
        contentCloudinary
        score
        order
        duration
        bookmark
        template
        baliseDescription
        bottomPopupEnabled
        simulatorEnabled
        titleSimulator
        descriptionSimulator
        btnSimulator
        urlSimulator
        imageTitle
        coverTitle
        contentBottomImageAlt
        contentBottomImageTitle
        updatedAt
        blog {
          id
          title
          baliseTitle
          description
          keywords
          slug
          image
          statusCode
          createdAt
          updatedAt
        }
        owner
      }
      owner
    }
  }
`;
export const createArticleFAQ = /* GraphQL */ `
  mutation CreateArticleFAQ(
    $input: CreateArticleFAQInput!
    $condition: ModelArticleFAQConditionInput
  ) {
    createArticleFAQ(input: $input, condition: $condition) {
      id
      articleID
      title
      description
      statusCode
      createdAt
      updatedAt
    }
  }
`;
export const updateArticleFAQ = /* GraphQL */ `
  mutation UpdateArticleFAQ(
    $input: UpdateArticleFAQInput!
    $condition: ModelArticleFAQConditionInput
  ) {
    updateArticleFAQ(input: $input, condition: $condition) {
      id
      articleID
      title
      description
      statusCode
      createdAt
      updatedAt
    }
  }
`;
export const deleteArticleFAQ = /* GraphQL */ `
  mutation DeleteArticleFAQ(
    $input: DeleteArticleFAQInput!
    $condition: ModelArticleFAQConditionInput
  ) {
    deleteArticleFAQ(input: $input, condition: $condition) {
      id
      articleID
      title
      description
      statusCode
      createdAt
      updatedAt
    }
  }
`;
export const createArticleComment = /* GraphQL */ `
  mutation CreateArticleComment(
    $input: CreateArticleCommentInput!
    $condition: ModelArticleCommentConditionInput
  ) {
    createArticleComment(input: $input, condition: $condition) {
      id
      articleID
      fullname
      email
      comment
      isPublic
      statusCode
      createdAt
      updatedAt
      article {
        id
        blogID
        slug
        balise_title
        title
        image
        cover
        description
        keywords
        content
        statusCode
        createdAt
        bottomPopupMessage
        bottomPopupButton
        bottomPopupUrl
        imageAlt
        coverAlt
        imageCloudinary
        coverCloudinary
        contentCloudinary
        score
        order
        duration
        bookmark
        template
        baliseDescription
        bottomPopupEnabled
        simulatorEnabled
        titleSimulator
        descriptionSimulator
        btnSimulator
        urlSimulator
        imageTitle
        coverTitle
        contentBottomImageAlt
        contentBottomImageTitle
        updatedAt
        blog {
          id
          title
          baliseTitle
          description
          keywords
          slug
          image
          statusCode
          createdAt
          updatedAt
        }
        owner
      }
      owner
      replyComments {
        items {
          id
          articleCommentID
          comment
          statusCode
          isPublic
          createdAt
          updatedAt
          articleComment {
            id
            articleID
            fullname
            email
            comment
            isPublic
            statusCode
            createdAt
            updatedAt
            article {
              id
              blogID
              slug
              balise_title
              title
              image
              cover
              description
              keywords
              content
              statusCode
              createdAt
              bottomPopupMessage
              bottomPopupButton
              bottomPopupUrl
              imageAlt
              coverAlt
              imageCloudinary
              coverCloudinary
              contentCloudinary
              score
              order
              duration
              bookmark
              template
              baliseDescription
              bottomPopupEnabled
              simulatorEnabled
              titleSimulator
              descriptionSimulator
              btnSimulator
              urlSimulator
              imageTitle
              coverTitle
              contentBottomImageAlt
              contentBottomImageTitle
              updatedAt
              owner
            }
            owner
            replyComments {
              nextToken
            }
          }
          owner
        }
        nextToken
      }
    }
  }
`;
export const updateArticleComment = /* GraphQL */ `
  mutation UpdateArticleComment(
    $input: UpdateArticleCommentInput!
    $condition: ModelArticleCommentConditionInput
  ) {
    updateArticleComment(input: $input, condition: $condition) {
      id
      articleID
      fullname
      email
      comment
      isPublic
      statusCode
      createdAt
      updatedAt
      article {
        id
        blogID
        slug
        balise_title
        title
        image
        cover
        description
        keywords
        content
        statusCode
        createdAt
        bottomPopupMessage
        bottomPopupButton
        bottomPopupUrl
        imageAlt
        coverAlt
        imageCloudinary
        coverCloudinary
        contentCloudinary
        score
        order
        duration
        bookmark
        template
        baliseDescription
        bottomPopupEnabled
        simulatorEnabled
        titleSimulator
        descriptionSimulator
        btnSimulator
        urlSimulator
        imageTitle
        coverTitle
        contentBottomImageAlt
        contentBottomImageTitle
        updatedAt
        blog {
          id
          title
          baliseTitle
          description
          keywords
          slug
          image
          statusCode
          createdAt
          updatedAt
        }
        owner
      }
      owner
      replyComments {
        items {
          id
          articleCommentID
          comment
          statusCode
          isPublic
          createdAt
          updatedAt
          articleComment {
            id
            articleID
            fullname
            email
            comment
            isPublic
            statusCode
            createdAt
            updatedAt
            article {
              id
              blogID
              slug
              balise_title
              title
              image
              cover
              description
              keywords
              content
              statusCode
              createdAt
              bottomPopupMessage
              bottomPopupButton
              bottomPopupUrl
              imageAlt
              coverAlt
              imageCloudinary
              coverCloudinary
              contentCloudinary
              score
              order
              duration
              bookmark
              template
              baliseDescription
              bottomPopupEnabled
              simulatorEnabled
              titleSimulator
              descriptionSimulator
              btnSimulator
              urlSimulator
              imageTitle
              coverTitle
              contentBottomImageAlt
              contentBottomImageTitle
              updatedAt
              owner
            }
            owner
            replyComments {
              nextToken
            }
          }
          owner
        }
        nextToken
      }
    }
  }
`;
export const deleteArticleComment = /* GraphQL */ `
  mutation DeleteArticleComment(
    $input: DeleteArticleCommentInput!
    $condition: ModelArticleCommentConditionInput
  ) {
    deleteArticleComment(input: $input, condition: $condition) {
      id
      articleID
      fullname
      email
      comment
      isPublic
      statusCode
      createdAt
      updatedAt
      article {
        id
        blogID
        slug
        balise_title
        title
        image
        cover
        description
        keywords
        content
        statusCode
        createdAt
        bottomPopupMessage
        bottomPopupButton
        bottomPopupUrl
        imageAlt
        coverAlt
        imageCloudinary
        coverCloudinary
        contentCloudinary
        score
        order
        duration
        bookmark
        template
        baliseDescription
        bottomPopupEnabled
        simulatorEnabled
        titleSimulator
        descriptionSimulator
        btnSimulator
        urlSimulator
        imageTitle
        coverTitle
        contentBottomImageAlt
        contentBottomImageTitle
        updatedAt
        blog {
          id
          title
          baliseTitle
          description
          keywords
          slug
          image
          statusCode
          createdAt
          updatedAt
        }
        owner
      }
      owner
      replyComments {
        items {
          id
          articleCommentID
          comment
          statusCode
          isPublic
          createdAt
          updatedAt
          articleComment {
            id
            articleID
            fullname
            email
            comment
            isPublic
            statusCode
            createdAt
            updatedAt
            article {
              id
              blogID
              slug
              balise_title
              title
              image
              cover
              description
              keywords
              content
              statusCode
              createdAt
              bottomPopupMessage
              bottomPopupButton
              bottomPopupUrl
              imageAlt
              coverAlt
              imageCloudinary
              coverCloudinary
              contentCloudinary
              score
              order
              duration
              bookmark
              template
              baliseDescription
              bottomPopupEnabled
              simulatorEnabled
              titleSimulator
              descriptionSimulator
              btnSimulator
              urlSimulator
              imageTitle
              coverTitle
              contentBottomImageAlt
              contentBottomImageTitle
              updatedAt
              owner
            }
            owner
            replyComments {
              nextToken
            }
          }
          owner
        }
        nextToken
      }
    }
  }
`;
export const createArticleReplyComment = /* GraphQL */ `
  mutation CreateArticleReplyComment(
    $input: CreateArticleReplyCommentInput!
    $condition: ModelArticleReplyCommentConditionInput
  ) {
    createArticleReplyComment(input: $input, condition: $condition) {
      id
      articleCommentID
      comment
      statusCode
      isPublic
      createdAt
      updatedAt
      articleComment {
        id
        articleID
        fullname
        email
        comment
        isPublic
        statusCode
        createdAt
        updatedAt
        article {
          id
          blogID
          slug
          balise_title
          title
          image
          cover
          description
          keywords
          content
          statusCode
          createdAt
          bottomPopupMessage
          bottomPopupButton
          bottomPopupUrl
          imageAlt
          coverAlt
          imageCloudinary
          coverCloudinary
          contentCloudinary
          score
          order
          duration
          bookmark
          template
          baliseDescription
          bottomPopupEnabled
          simulatorEnabled
          titleSimulator
          descriptionSimulator
          btnSimulator
          urlSimulator
          imageTitle
          coverTitle
          contentBottomImageAlt
          contentBottomImageTitle
          updatedAt
          blog {
            id
            title
            baliseTitle
            description
            keywords
            slug
            image
            statusCode
            createdAt
            updatedAt
          }
          owner
        }
        owner
        replyComments {
          items {
            id
            articleCommentID
            comment
            statusCode
            isPublic
            createdAt
            updatedAt
            articleComment {
              id
              articleID
              fullname
              email
              comment
              isPublic
              statusCode
              createdAt
              updatedAt
              owner
            }
            owner
          }
          nextToken
        }
      }
      owner
    }
  }
`;
export const updateArticleReplyComment = /* GraphQL */ `
  mutation UpdateArticleReplyComment(
    $input: UpdateArticleReplyCommentInput!
    $condition: ModelArticleReplyCommentConditionInput
  ) {
    updateArticleReplyComment(input: $input, condition: $condition) {
      id
      articleCommentID
      comment
      statusCode
      isPublic
      createdAt
      updatedAt
      articleComment {
        id
        articleID
        fullname
        email
        comment
        isPublic
        statusCode
        createdAt
        updatedAt
        article {
          id
          blogID
          slug
          balise_title
          title
          image
          cover
          description
          keywords
          content
          statusCode
          createdAt
          bottomPopupMessage
          bottomPopupButton
          bottomPopupUrl
          imageAlt
          coverAlt
          imageCloudinary
          coverCloudinary
          contentCloudinary
          score
          order
          duration
          bookmark
          template
          baliseDescription
          bottomPopupEnabled
          simulatorEnabled
          titleSimulator
          descriptionSimulator
          btnSimulator
          urlSimulator
          imageTitle
          coverTitle
          contentBottomImageAlt
          contentBottomImageTitle
          updatedAt
          blog {
            id
            title
            baliseTitle
            description
            keywords
            slug
            image
            statusCode
            createdAt
            updatedAt
          }
          owner
        }
        owner
        replyComments {
          items {
            id
            articleCommentID
            comment
            statusCode
            isPublic
            createdAt
            updatedAt
            articleComment {
              id
              articleID
              fullname
              email
              comment
              isPublic
              statusCode
              createdAt
              updatedAt
              owner
            }
            owner
          }
          nextToken
        }
      }
      owner
    }
  }
`;
export const deleteArticleReplyComment = /* GraphQL */ `
  mutation DeleteArticleReplyComment(
    $input: DeleteArticleReplyCommentInput!
    $condition: ModelArticleReplyCommentConditionInput
  ) {
    deleteArticleReplyComment(input: $input, condition: $condition) {
      id
      articleCommentID
      comment
      statusCode
      isPublic
      createdAt
      updatedAt
      articleComment {
        id
        articleID
        fullname
        email
        comment
        isPublic
        statusCode
        createdAt
        updatedAt
        article {
          id
          blogID
          slug
          balise_title
          title
          image
          cover
          description
          keywords
          content
          statusCode
          createdAt
          bottomPopupMessage
          bottomPopupButton
          bottomPopupUrl
          imageAlt
          coverAlt
          imageCloudinary
          coverCloudinary
          contentCloudinary
          score
          order
          duration
          bookmark
          template
          baliseDescription
          bottomPopupEnabled
          simulatorEnabled
          titleSimulator
          descriptionSimulator
          btnSimulator
          urlSimulator
          imageTitle
          coverTitle
          contentBottomImageAlt
          contentBottomImageTitle
          updatedAt
          blog {
            id
            title
            baliseTitle
            description
            keywords
            slug
            image
            statusCode
            createdAt
            updatedAt
          }
          owner
        }
        owner
        replyComments {
          items {
            id
            articleCommentID
            comment
            statusCode
            isPublic
            createdAt
            updatedAt
            articleComment {
              id
              articleID
              fullname
              email
              comment
              isPublic
              statusCode
              createdAt
              updatedAt
              owner
            }
            owner
          }
          nextToken
        }
      }
      owner
    }
  }
`;
export const createContract = /* GraphQL */ `
  mutation CreateContract(
    $input: CreateContractInput!
    $condition: ModelContractConditionInput
  ) {
    createContract(input: $input, condition: $condition) {
      id
      prospectID
      packageID
      customerID
      postalCode
      city
      isSigned
      isWantMail
      unPaid
      contractNumnber
      companyName
      companyAddress
      companyPhoneNumber
      userID
      asignedUser
      fromDate
      toDate
      extendDate
      hasTrial
      terminateDate
      note
      attachments
      orderID
      createdAt
      updatedAt
      owner
      order {
        id
        prospectID
        packageID
        customerID
        orderDate
        fromDate
        month
        year
        toDate
        taxAmount
        discountAmount
        totalAmount
        currency
        note
        orderStatus
        createdAt
        updatedAt
        owner
      }
      customer {
        id
        prospectID
        email
        phone_number
        address
        numberStreet
        additionalAddress
        zipCode
        city
        company_already_created
        packageID
        payment
        fullname
        statusCode
        verified
        contract
        identityFront
        identityBack
        createdAt
        createdAtTimestamp
        updatedAt
        owner
      }
      package {
        id
        order
        name
        price
        currency
        description
        note
        bestChoice
        button
        features
        buttonLoadMore
        colorButtonLoadMore
        code
        createdAt
        updatedAt
        steps {
          items {
            id
            packageID
            step
            summary
            features
            createdAt
            updatedAt
            package {
              id
              order
              name
              price
              currency
              description
              note
              bestChoice
              button
              features
              buttonLoadMore
              colorButtonLoadMore
              code
              createdAt
              updatedAt
            }
            owner
          }
          nextToken
        }
        options {
          items {
            id
            packageID
            price
            code
            name
            description
            statusCode
            createdAt
            updatedAt
            package {
              id
              order
              name
              price
              currency
              description
              note
              bestChoice
              button
              features
              buttonLoadMore
              colorButtonLoadMore
              code
              createdAt
              updatedAt
            }
            owner
          }
          nextToken
        }
      }
    }
  }
`;
export const updateContract = /* GraphQL */ `
  mutation UpdateContract(
    $input: UpdateContractInput!
    $condition: ModelContractConditionInput
  ) {
    updateContract(input: $input, condition: $condition) {
      id
      prospectID
      packageID
      customerID
      postalCode
      city
      isSigned
      isWantMail
      unPaid
      contractNumnber
      companyName
      companyAddress
      companyPhoneNumber
      userID
      asignedUser
      fromDate
      toDate
      extendDate
      hasTrial
      terminateDate
      note
      attachments
      orderID
      createdAt
      updatedAt
      owner
      order {
        id
        prospectID
        packageID
        customerID
        orderDate
        fromDate
        month
        year
        toDate
        taxAmount
        discountAmount
        totalAmount
        currency
        note
        orderStatus
        createdAt
        updatedAt
        owner
      }
      customer {
        id
        prospectID
        email
        phone_number
        address
        numberStreet
        additionalAddress
        zipCode
        city
        company_already_created
        packageID
        payment
        fullname
        statusCode
        verified
        contract
        identityFront
        identityBack
        createdAt
        createdAtTimestamp
        updatedAt
        owner
      }
      package {
        id
        order
        name
        price
        currency
        description
        note
        bestChoice
        button
        features
        buttonLoadMore
        colorButtonLoadMore
        code
        createdAt
        updatedAt
        steps {
          items {
            id
            packageID
            step
            summary
            features
            createdAt
            updatedAt
            package {
              id
              order
              name
              price
              currency
              description
              note
              bestChoice
              button
              features
              buttonLoadMore
              colorButtonLoadMore
              code
              createdAt
              updatedAt
            }
            owner
          }
          nextToken
        }
        options {
          items {
            id
            packageID
            price
            code
            name
            description
            statusCode
            createdAt
            updatedAt
            package {
              id
              order
              name
              price
              currency
              description
              note
              bestChoice
              button
              features
              buttonLoadMore
              colorButtonLoadMore
              code
              createdAt
              updatedAt
            }
            owner
          }
          nextToken
        }
      }
    }
  }
`;
export const createOrder = /* GraphQL */ `
  mutation CreateOrder(
    $input: CreateOrderInput!
    $condition: ModelOrderConditionInput
  ) {
    createOrder(input: $input, condition: $condition) {
      id
      prospectID
      packageID
      customerID
      orderDate
      fromDate
      month
      year
      toDate
      taxAmount
      discountAmount
      totalAmount
      currency
      note
      orderStatus
      createdAt
      updatedAt
      owner
    }
  }
`;
export const updateOrder = /* GraphQL */ `
  mutation UpdateOrder(
    $input: UpdateOrderInput!
    $condition: ModelOrderConditionInput
  ) {
    updateOrder(input: $input, condition: $condition) {
      id
      prospectID
      packageID
      customerID
      orderDate
      fromDate
      month
      year
      toDate
      taxAmount
      discountAmount
      totalAmount
      currency
      note
      orderStatus
      createdAt
      updatedAt
      owner
    }
  }
`;
export const createInvoice = /* GraphQL */ `
  mutation CreateInvoice(
    $input: CreateInvoiceInput!
    $condition: ModelInvoiceConditionInput
  ) {
    createInvoice(input: $input, condition: $condition) {
      id
      orderID
      paidAmount
      currency
      month
      year
      isFullPaid
      invoiceDate
      invoiceAddress
      invoiceName
      description
      note
      paymentMethod
      customerStripeID
      invoiceStatus
      createdAt
      updatedAt
      order {
        id
        prospectID
        packageID
        customerID
        orderDate
        fromDate
        month
        year
        toDate
        taxAmount
        discountAmount
        totalAmount
        currency
        note
        orderStatus
        createdAt
        updatedAt
        owner
      }
      owner
    }
  }
`;
export const updateInvoice = /* GraphQL */ `
  mutation UpdateInvoice(
    $input: UpdateInvoiceInput!
    $condition: ModelInvoiceConditionInput
  ) {
    updateInvoice(input: $input, condition: $condition) {
      id
      orderID
      paidAmount
      currency
      month
      year
      isFullPaid
      invoiceDate
      invoiceAddress
      invoiceName
      description
      note
      paymentMethod
      customerStripeID
      invoiceStatus
      createdAt
      updatedAt
      order {
        id
        prospectID
        packageID
        customerID
        orderDate
        fromDate
        month
        year
        toDate
        taxAmount
        discountAmount
        totalAmount
        currency
        note
        orderStatus
        createdAt
        updatedAt
        owner
      }
      owner
    }
  }
`;
export const createCustomer = /* GraphQL */ `
  mutation CreateCustomer(
    $input: CreateCustomerInput!
    $condition: ModelCustomerConditionInput
  ) {
    createCustomer(input: $input, condition: $condition) {
      id
      prospectID
      email
      phone_number
      address
      numberStreet
      additionalAddress
      zipCode
      city
      company_already_created
      packageID
      payment
      fullname
      statusCode
      verified
      contract
      identityFront
      identityBack
      createdAt
      createdAtTimestamp
      updatedAt
      owner
    }
  }
`;
export const updateCustomer = /* GraphQL */ `
  mutation UpdateCustomer(
    $input: UpdateCustomerInput!
    $condition: ModelCustomerConditionInput
  ) {
    updateCustomer(input: $input, condition: $condition) {
      id
      prospectID
      email
      phone_number
      address
      numberStreet
      additionalAddress
      zipCode
      city
      company_already_created
      packageID
      payment
      fullname
      statusCode
      verified
      contract
      identityFront
      identityBack
      createdAt
      createdAtTimestamp
      updatedAt
      owner
    }
  }
`;
export const createCustomerNote = /* GraphQL */ `
  mutation CreateCustomerNote(
    $input: CreateCustomerNoteInput!
    $condition: ModelCustomerNoteConditionInput
  ) {
    createCustomerNote(input: $input, condition: $condition) {
      id
      customerID
      note
      createdAt
      updatedAt
      owner
    }
  }
`;
export const updateCustomerNote = /* GraphQL */ `
  mutation UpdateCustomerNote(
    $input: UpdateCustomerNoteInput!
    $condition: ModelCustomerNoteConditionInput
  ) {
    updateCustomerNote(input: $input, condition: $condition) {
      id
      customerID
      note
      createdAt
      updatedAt
      owner
    }
  }
`;
export const deleteCustomerNote = /* GraphQL */ `
  mutation DeleteCustomerNote(
    $input: DeleteCustomerNoteInput!
    $condition: ModelCustomerNoteConditionInput
  ) {
    deleteCustomerNote(input: $input, condition: $condition) {
      id
      customerID
      note
      createdAt
      updatedAt
      owner
    }
  }
`;
export const createCustomerStripe = /* GraphQL */ `
  mutation CreateCustomerStripe(
    $input: CreateCustomerStripeInput!
    $condition: ModelCustomerStripeConditionInput
  ) {
    createCustomerStripe(input: $input, condition: $condition) {
      id
      customerID
      amount
      name
      chargesID
      payment_method
      receipt_url
      PaymentIntentID
      createdAt
      updatedAt
      owner
    }
  }
`;
export const createCategoryDocument = /* GraphQL */ `
  mutation CreateCategoryDocument(
    $input: CreateCategoryDocumentInput!
    $condition: ModelCategoryDocumentConditionInput
  ) {
    createCategoryDocument(input: $input, condition: $condition) {
      id
      order
      title
      statusCode
      createdAt
      updatedAt
      owner
    }
  }
`;
export const updateCategoryDocument = /* GraphQL */ `
  mutation UpdateCategoryDocument(
    $input: UpdateCategoryDocumentInput!
    $condition: ModelCategoryDocumentConditionInput
  ) {
    updateCategoryDocument(input: $input, condition: $condition) {
      id
      order
      title
      statusCode
      createdAt
      updatedAt
      owner
    }
  }
`;
export const deleteCategoryDocument = /* GraphQL */ `
  mutation DeleteCategoryDocument(
    $input: DeleteCategoryDocumentInput!
    $condition: ModelCategoryDocumentConditionInput
  ) {
    deleteCategoryDocument(input: $input, condition: $condition) {
      id
      order
      title
      statusCode
      createdAt
      updatedAt
      owner
    }
  }
`;
export const createDocument = /* GraphQL */ `
  mutation CreateDocument(
    $input: CreateDocumentInput!
    $condition: ModelDocumentConditionInput
  ) {
    createDocument(input: $input, condition: $condition) {
      id
      categoryDocumentID
      order
      title
      file
      statusCode
      createdAt
      updatedAt
      categoryDocument {
        id
        order
        title
        statusCode
        createdAt
        updatedAt
        owner
      }
      owner
    }
  }
`;
export const updateDocument = /* GraphQL */ `
  mutation UpdateDocument(
    $input: UpdateDocumentInput!
    $condition: ModelDocumentConditionInput
  ) {
    updateDocument(input: $input, condition: $condition) {
      id
      categoryDocumentID
      order
      title
      file
      statusCode
      createdAt
      updatedAt
      categoryDocument {
        id
        order
        title
        statusCode
        createdAt
        updatedAt
        owner
      }
      owner
    }
  }
`;
export const deleteDocument = /* GraphQL */ `
  mutation DeleteDocument(
    $input: DeleteDocumentInput!
    $condition: ModelDocumentConditionInput
  ) {
    deleteDocument(input: $input, condition: $condition) {
      id
      categoryDocumentID
      order
      title
      file
      statusCode
      createdAt
      updatedAt
      categoryDocument {
        id
        order
        title
        statusCode
        createdAt
        updatedAt
        owner
      }
      owner
    }
  }
`;
export const createGoogleService = /* GraphQL */ `
  mutation CreateGoogleService(
    $input: CreateGoogleServiceInput!
    $condition: ModelGoogleServiceConditionInput
  ) {
    createGoogleService(input: $input, condition: $condition) {
      id
      message
      data
      prospectID
      statusCode
      googleServiceType
      calendarId
      eventId
      startDate
      endDate
      summary
      description
      location
      guests
      createdAt
      updatedAt
      owner
    }
  }
`;
export const updateGoogleService = /* GraphQL */ `
  mutation UpdateGoogleService(
    $input: UpdateGoogleServiceInput!
    $condition: ModelGoogleServiceConditionInput
  ) {
    updateGoogleService(input: $input, condition: $condition) {
      id
      message
      data
      prospectID
      statusCode
      googleServiceType
      calendarId
      eventId
      startDate
      endDate
      summary
      description
      location
      guests
      createdAt
      updatedAt
      owner
    }
  }
`;
export const deleteGoogleService = /* GraphQL */ `
  mutation DeleteGoogleService(
    $input: DeleteGoogleServiceInput!
    $condition: ModelGoogleServiceConditionInput
  ) {
    deleteGoogleService(input: $input, condition: $condition) {
      id
      message
      data
      prospectID
      statusCode
      googleServiceType
      calendarId
      eventId
      startDate
      endDate
      summary
      description
      location
      guests
      createdAt
      updatedAt
      owner
    }
  }
`;
export const createPackage = /* GraphQL */ `
  mutation CreatePackage(
    $input: CreatePackageInput!
    $condition: ModelPackageConditionInput
  ) {
    createPackage(input: $input, condition: $condition) {
      id
      order
      name
      price
      currency
      description
      note
      bestChoice
      button
      features
      buttonLoadMore
      colorButtonLoadMore
      code
      createdAt
      updatedAt
      steps {
        items {
          id
          packageID
          step
          summary
          features
          createdAt
          updatedAt
          package {
            id
            order
            name
            price
            currency
            description
            note
            bestChoice
            button
            features
            buttonLoadMore
            colorButtonLoadMore
            code
            createdAt
            updatedAt
            steps {
              nextToken
            }
            options {
              nextToken
            }
          }
          owner
        }
        nextToken
      }
      options {
        items {
          id
          packageID
          price
          code
          name
          description
          statusCode
          createdAt
          updatedAt
          package {
            id
            order
            name
            price
            currency
            description
            note
            bestChoice
            button
            features
            buttonLoadMore
            colorButtonLoadMore
            code
            createdAt
            updatedAt
            steps {
              nextToken
            }
            options {
              nextToken
            }
          }
          owner
        }
        nextToken
      }
    }
  }
`;
export const updatePackage = /* GraphQL */ `
  mutation UpdatePackage(
    $input: UpdatePackageInput!
    $condition: ModelPackageConditionInput
  ) {
    updatePackage(input: $input, condition: $condition) {
      id
      order
      name
      price
      currency
      description
      note
      bestChoice
      button
      features
      buttonLoadMore
      colorButtonLoadMore
      code
      createdAt
      updatedAt
      steps {
        items {
          id
          packageID
          step
          summary
          features
          createdAt
          updatedAt
          package {
            id
            order
            name
            price
            currency
            description
            note
            bestChoice
            button
            features
            buttonLoadMore
            colorButtonLoadMore
            code
            createdAt
            updatedAt
            steps {
              nextToken
            }
            options {
              nextToken
            }
          }
          owner
        }
        nextToken
      }
      options {
        items {
          id
          packageID
          price
          code
          name
          description
          statusCode
          createdAt
          updatedAt
          package {
            id
            order
            name
            price
            currency
            description
            note
            bestChoice
            button
            features
            buttonLoadMore
            colorButtonLoadMore
            code
            createdAt
            updatedAt
            steps {
              nextToken
            }
            options {
              nextToken
            }
          }
          owner
        }
        nextToken
      }
    }
  }
`;
export const deletePackage = /* GraphQL */ `
  mutation DeletePackage(
    $input: DeletePackageInput!
    $condition: ModelPackageConditionInput
  ) {
    deletePackage(input: $input, condition: $condition) {
      id
      order
      name
      price
      currency
      description
      note
      bestChoice
      button
      features
      buttonLoadMore
      colorButtonLoadMore
      code
      createdAt
      updatedAt
      steps {
        items {
          id
          packageID
          step
          summary
          features
          createdAt
          updatedAt
          package {
            id
            order
            name
            price
            currency
            description
            note
            bestChoice
            button
            features
            buttonLoadMore
            colorButtonLoadMore
            code
            createdAt
            updatedAt
            steps {
              nextToken
            }
            options {
              nextToken
            }
          }
          owner
        }
        nextToken
      }
      options {
        items {
          id
          packageID
          price
          code
          name
          description
          statusCode
          createdAt
          updatedAt
          package {
            id
            order
            name
            price
            currency
            description
            note
            bestChoice
            button
            features
            buttonLoadMore
            colorButtonLoadMore
            code
            createdAt
            updatedAt
            steps {
              nextToken
            }
            options {
              nextToken
            }
          }
          owner
        }
        nextToken
      }
    }
  }
`;
export const createStepPackage = /* GraphQL */ `
  mutation CreateStepPackage(
    $input: CreateStepPackageInput!
    $condition: ModelStepPackageConditionInput
  ) {
    createStepPackage(input: $input, condition: $condition) {
      id
      packageID
      step
      summary
      features
      createdAt
      updatedAt
      package {
        id
        order
        name
        price
        currency
        description
        note
        bestChoice
        button
        features
        buttonLoadMore
        colorButtonLoadMore
        code
        createdAt
        updatedAt
        steps {
          items {
            id
            packageID
            step
            summary
            features
            createdAt
            updatedAt
            package {
              id
              order
              name
              price
              currency
              description
              note
              bestChoice
              button
              features
              buttonLoadMore
              colorButtonLoadMore
              code
              createdAt
              updatedAt
            }
            owner
          }
          nextToken
        }
        options {
          items {
            id
            packageID
            price
            code
            name
            description
            statusCode
            createdAt
            updatedAt
            package {
              id
              order
              name
              price
              currency
              description
              note
              bestChoice
              button
              features
              buttonLoadMore
              colorButtonLoadMore
              code
              createdAt
              updatedAt
            }
            owner
          }
          nextToken
        }
      }
      owner
    }
  }
`;
export const updateStepPackage = /* GraphQL */ `
  mutation UpdateStepPackage(
    $input: UpdateStepPackageInput!
    $condition: ModelStepPackageConditionInput
  ) {
    updateStepPackage(input: $input, condition: $condition) {
      id
      packageID
      step
      summary
      features
      createdAt
      updatedAt
      package {
        id
        order
        name
        price
        currency
        description
        note
        bestChoice
        button
        features
        buttonLoadMore
        colorButtonLoadMore
        code
        createdAt
        updatedAt
        steps {
          items {
            id
            packageID
            step
            summary
            features
            createdAt
            updatedAt
            package {
              id
              order
              name
              price
              currency
              description
              note
              bestChoice
              button
              features
              buttonLoadMore
              colorButtonLoadMore
              code
              createdAt
              updatedAt
            }
            owner
          }
          nextToken
        }
        options {
          items {
            id
            packageID
            price
            code
            name
            description
            statusCode
            createdAt
            updatedAt
            package {
              id
              order
              name
              price
              currency
              description
              note
              bestChoice
              button
              features
              buttonLoadMore
              colorButtonLoadMore
              code
              createdAt
              updatedAt
            }
            owner
          }
          nextToken
        }
      }
      owner
    }
  }
`;
export const deleteStepPackage = /* GraphQL */ `
  mutation DeleteStepPackage(
    $input: DeleteStepPackageInput!
    $condition: ModelStepPackageConditionInput
  ) {
    deleteStepPackage(input: $input, condition: $condition) {
      id
      packageID
      step
      summary
      features
      createdAt
      updatedAt
      package {
        id
        order
        name
        price
        currency
        description
        note
        bestChoice
        button
        features
        buttonLoadMore
        colorButtonLoadMore
        code
        createdAt
        updatedAt
        steps {
          items {
            id
            packageID
            step
            summary
            features
            createdAt
            updatedAt
            package {
              id
              order
              name
              price
              currency
              description
              note
              bestChoice
              button
              features
              buttonLoadMore
              colorButtonLoadMore
              code
              createdAt
              updatedAt
            }
            owner
          }
          nextToken
        }
        options {
          items {
            id
            packageID
            price
            code
            name
            description
            statusCode
            createdAt
            updatedAt
            package {
              id
              order
              name
              price
              currency
              description
              note
              bestChoice
              button
              features
              buttonLoadMore
              colorButtonLoadMore
              code
              createdAt
              updatedAt
            }
            owner
          }
          nextToken
        }
      }
      owner
    }
  }
`;
export const createOptionPackage = /* GraphQL */ `
  mutation CreateOptionPackage(
    $input: CreateOptionPackageInput!
    $condition: ModelOptionPackageConditionInput
  ) {
    createOptionPackage(input: $input, condition: $condition) {
      id
      packageID
      price
      code
      name
      description
      statusCode
      createdAt
      updatedAt
      package {
        id
        order
        name
        price
        currency
        description
        note
        bestChoice
        button
        features
        buttonLoadMore
        colorButtonLoadMore
        code
        createdAt
        updatedAt
        steps {
          items {
            id
            packageID
            step
            summary
            features
            createdAt
            updatedAt
            package {
              id
              order
              name
              price
              currency
              description
              note
              bestChoice
              button
              features
              buttonLoadMore
              colorButtonLoadMore
              code
              createdAt
              updatedAt
            }
            owner
          }
          nextToken
        }
        options {
          items {
            id
            packageID
            price
            code
            name
            description
            statusCode
            createdAt
            updatedAt
            package {
              id
              order
              name
              price
              currency
              description
              note
              bestChoice
              button
              features
              buttonLoadMore
              colorButtonLoadMore
              code
              createdAt
              updatedAt
            }
            owner
          }
          nextToken
        }
      }
      owner
    }
  }
`;
export const updateOptionPackage = /* GraphQL */ `
  mutation UpdateOptionPackage(
    $input: UpdateOptionPackageInput!
    $condition: ModelOptionPackageConditionInput
  ) {
    updateOptionPackage(input: $input, condition: $condition) {
      id
      packageID
      price
      code
      name
      description
      statusCode
      createdAt
      updatedAt
      package {
        id
        order
        name
        price
        currency
        description
        note
        bestChoice
        button
        features
        buttonLoadMore
        colorButtonLoadMore
        code
        createdAt
        updatedAt
        steps {
          items {
            id
            packageID
            step
            summary
            features
            createdAt
            updatedAt
            package {
              id
              order
              name
              price
              currency
              description
              note
              bestChoice
              button
              features
              buttonLoadMore
              colorButtonLoadMore
              code
              createdAt
              updatedAt
            }
            owner
          }
          nextToken
        }
        options {
          items {
            id
            packageID
            price
            code
            name
            description
            statusCode
            createdAt
            updatedAt
            package {
              id
              order
              name
              price
              currency
              description
              note
              bestChoice
              button
              features
              buttonLoadMore
              colorButtonLoadMore
              code
              createdAt
              updatedAt
            }
            owner
          }
          nextToken
        }
      }
      owner
    }
  }
`;
export const deleteOptionPackage = /* GraphQL */ `
  mutation DeleteOptionPackage(
    $input: DeleteOptionPackageInput!
    $condition: ModelOptionPackageConditionInput
  ) {
    deleteOptionPackage(input: $input, condition: $condition) {
      id
      packageID
      price
      code
      name
      description
      statusCode
      createdAt
      updatedAt
      package {
        id
        order
        name
        price
        currency
        description
        note
        bestChoice
        button
        features
        buttonLoadMore
        colorButtonLoadMore
        code
        createdAt
        updatedAt
        steps {
          items {
            id
            packageID
            step
            summary
            features
            createdAt
            updatedAt
            package {
              id
              order
              name
              price
              currency
              description
              note
              bestChoice
              button
              features
              buttonLoadMore
              colorButtonLoadMore
              code
              createdAt
              updatedAt
            }
            owner
          }
          nextToken
        }
        options {
          items {
            id
            packageID
            price
            code
            name
            description
            statusCode
            createdAt
            updatedAt
            package {
              id
              order
              name
              price
              currency
              description
              note
              bestChoice
              button
              features
              buttonLoadMore
              colorButtonLoadMore
              code
              createdAt
              updatedAt
            }
            owner
          }
          nextToken
        }
      }
      owner
    }
  }
`;
export const createStaticPage = /* GraphQL */ `
  mutation CreateStaticPage(
    $input: CreateStaticPageInput!
    $condition: ModelStaticPageConditionInput
  ) {
    createStaticPage(input: $input, condition: $condition) {
      id
      page
      slug
      title
      image
      description
      baliseTitle
      keywords
      statusCode
      createdAt
      updatedAt
    }
  }
`;
export const updateStaticPage = /* GraphQL */ `
  mutation UpdateStaticPage(
    $input: UpdateStaticPageInput!
    $condition: ModelStaticPageConditionInput
  ) {
    updateStaticPage(input: $input, condition: $condition) {
      id
      page
      slug
      title
      image
      description
      baliseTitle
      keywords
      statusCode
      createdAt
      updatedAt
    }
  }
`;
export const deleteStaticPage = /* GraphQL */ `
  mutation DeleteStaticPage(
    $input: DeleteStaticPageInput!
    $condition: ModelStaticPageConditionInput
  ) {
    deleteStaticPage(input: $input, condition: $condition) {
      id
      page
      slug
      title
      image
      description
      baliseTitle
      keywords
      statusCode
      createdAt
      updatedAt
    }
  }
`;
export const createStaticPageFAQ = /* GraphQL */ `
  mutation CreateStaticPageFAQ(
    $input: CreateStaticPageFAQInput!
    $condition: ModelStaticPageFAQConditionInput
  ) {
    createStaticPageFAQ(input: $input, condition: $condition) {
      id
      staticPageID
      title
      description
      statusCode
      createdAt
      updatedAt
    }
  }
`;
export const updateStaticPageFAQ = /* GraphQL */ `
  mutation UpdateStaticPageFAQ(
    $input: UpdateStaticPageFAQInput!
    $condition: ModelStaticPageFAQConditionInput
  ) {
    updateStaticPageFAQ(input: $input, condition: $condition) {
      id
      staticPageID
      title
      description
      statusCode
      createdAt
      updatedAt
    }
  }
`;
export const deleteStaticPageFAQ = /* GraphQL */ `
  mutation DeleteStaticPageFAQ(
    $input: DeleteStaticPageFAQInput!
    $condition: ModelStaticPageFAQConditionInput
  ) {
    deleteStaticPageFAQ(input: $input, condition: $condition) {
      id
      staticPageID
      title
      description
      statusCode
      createdAt
      updatedAt
    }
  }
`;
export const createPage = /* GraphQL */ `
  mutation CreatePage(
    $input: CreatePageInput!
    $condition: ModelPageConditionInput
  ) {
    createPage(input: $input, condition: $condition) {
      id
      slug
      title
      image
      baliseTitle
      description
      keywords
      content
      statusCode
      createdAt
      updatedAt
    }
  }
`;
export const updatePage = /* GraphQL */ `
  mutation UpdatePage(
    $input: UpdatePageInput!
    $condition: ModelPageConditionInput
  ) {
    updatePage(input: $input, condition: $condition) {
      id
      slug
      title
      image
      baliseTitle
      description
      keywords
      content
      statusCode
      createdAt
      updatedAt
    }
  }
`;
export const deletePage = /* GraphQL */ `
  mutation DeletePage(
    $input: DeletePageInput!
    $condition: ModelPageConditionInput
  ) {
    deletePage(input: $input, condition: $condition) {
      id
      slug
      title
      image
      baliseTitle
      description
      keywords
      content
      statusCode
      createdAt
      updatedAt
    }
  }
`;
export const createWhoAreWe = /* GraphQL */ `
  mutation CreateWhoAreWe(
    $input: CreateWhoAreWeInput!
    $condition: ModelWhoAreWeConditionInput
  ) {
    createWhoAreWe(input: $input, condition: $condition) {
      id
      title
      subtitle
      image
      description
      statusCode
      createdAt
      updatedAt
    }
  }
`;
export const updateWhoAreWe = /* GraphQL */ `
  mutation UpdateWhoAreWe(
    $input: UpdateWhoAreWeInput!
    $condition: ModelWhoAreWeConditionInput
  ) {
    updateWhoAreWe(input: $input, condition: $condition) {
      id
      title
      subtitle
      image
      description
      statusCode
      createdAt
      updatedAt
    }
  }
`;
export const deleteWhoAreWe = /* GraphQL */ `
  mutation DeleteWhoAreWe(
    $input: DeleteWhoAreWeInput!
    $condition: ModelWhoAreWeConditionInput
  ) {
    deleteWhoAreWe(input: $input, condition: $condition) {
      id
      title
      subtitle
      image
      description
      statusCode
      createdAt
      updatedAt
    }
  }
`;
export const createClient = /* GraphQL */ `
  mutation CreateClient(
    $input: CreateClientInput!
    $condition: ModelClientConditionInput
  ) {
    createClient(input: $input, condition: $condition) {
      id
      title
      subtitle
      image
      rating
      description
      statusCode
      createdAt
      updatedAt
    }
  }
`;
export const updateClient = /* GraphQL */ `
  mutation UpdateClient(
    $input: UpdateClientInput!
    $condition: ModelClientConditionInput
  ) {
    updateClient(input: $input, condition: $condition) {
      id
      title
      subtitle
      image
      rating
      description
      statusCode
      createdAt
      updatedAt
    }
  }
`;
export const deleteClient = /* GraphQL */ `
  mutation DeleteClient(
    $input: DeleteClientInput!
    $condition: ModelClientConditionInput
  ) {
    deleteClient(input: $input, condition: $condition) {
      id
      title
      subtitle
      image
      rating
      description
      statusCode
      createdAt
      updatedAt
    }
  }
`;
export const createFAQ = /* GraphQL */ `
  mutation CreateFAQ(
    $input: CreateFAQInput!
    $condition: ModelFAQConditionInput
  ) {
    createFAQ(input: $input, condition: $condition) {
      id
      title
      description
      statusCode
      createdAt
      updatedAt
    }
  }
`;
export const updateFAQ = /* GraphQL */ `
  mutation UpdateFAQ(
    $input: UpdateFAQInput!
    $condition: ModelFAQConditionInput
  ) {
    updateFAQ(input: $input, condition: $condition) {
      id
      title
      description
      statusCode
      createdAt
      updatedAt
    }
  }
`;
export const deleteFAQ = /* GraphQL */ `
  mutation DeleteFAQ(
    $input: DeleteFAQInput!
    $condition: ModelFAQConditionInput
  ) {
    deleteFAQ(input: $input, condition: $condition) {
      id
      title
      description
      statusCode
      createdAt
      updatedAt
    }
  }
`;
export const createProspect = /* GraphQL */ `
  mutation CreateProspect(
    $input: CreateProspectInput!
    $condition: ModelProspectConditionInput
  ) {
    createProspect(input: $input, condition: $condition) {
      id
      email
      phone_number
      address
      numberStreet
      additionalAddress
      zipCode
      city
      company_already_created
      packageID
      payment
      fullname
      processStatusCode
      statusCode
      emailReminderTimes
      smsReminderTimes
      token
      verified
      createdAt
      createdAtTimestamp
      ProspectUserId
      signatureDate
      companyName
      sigle
      legalStatus
      object
      capital
      capitalMini
      capitalInitial
      duration
      greffe
      civility
      name
      firstName
      meetingAndVoteType
      meetingAndVoteDesc
      clausesDagrements
      clausesDagrementsDesc
      updatedAt
      owner
    }
  }
`;
export const updateProspect = /* GraphQL */ `
  mutation UpdateProspect(
    $input: UpdateProspectInput!
    $condition: ModelProspectConditionInput
  ) {
    updateProspect(input: $input, condition: $condition) {
      id
      email
      phone_number
      address
      numberStreet
      additionalAddress
      zipCode
      city
      company_already_created
      packageID
      payment
      fullname
      processStatusCode
      statusCode
      emailReminderTimes
      smsReminderTimes
      token
      verified
      createdAt
      createdAtTimestamp
      ProspectUserId
      signatureDate
      companyName
      sigle
      legalStatus
      object
      capital
      capitalMini
      capitalInitial
      duration
      greffe
      civility
      name
      firstName
      meetingAndVoteType
      meetingAndVoteDesc
      clausesDagrements
      clausesDagrementsDesc
      updatedAt
      owner
    }
  }
`;
export const createProspectOption = /* GraphQL */ `
  mutation CreateProspectOption(
    $input: CreateProspectOptionInput!
    $condition: ModelProspectOptionConditionInput
  ) {
    createProspectOption(input: $input, condition: $condition) {
      id
      prospectID
      optionCode
      statusCode
      createdAt
      updatedAt
      owner
    }
  }
`;
export const updateProspectOption = /* GraphQL */ `
  mutation UpdateProspectOption(
    $input: UpdateProspectOptionInput!
    $condition: ModelProspectOptionConditionInput
  ) {
    updateProspectOption(input: $input, condition: $condition) {
      id
      prospectID
      optionCode
      statusCode
      createdAt
      updatedAt
      owner
    }
  }
`;
export const createPic = /* GraphQL */ `
  mutation CreatePic(
    $input: CreatePicInput!
    $condition: ModelPicConditionInput
  ) {
    createPic(input: $input, condition: $condition) {
      id
      civility
      name
      firstName
      address
      additionalAddress
      pays
      zipCode
      city
      prospectID
      code
      denomination
      type
      formeJuridique
      capital
      greffe
      rsc
      level
      createdAt
      updatedAt
      owner
    }
  }
`;
export const updatePic = /* GraphQL */ `
  mutation UpdatePic(
    $input: UpdatePicInput!
    $condition: ModelPicConditionInput
  ) {
    updatePic(input: $input, condition: $condition) {
      id
      civility
      name
      firstName
      address
      additionalAddress
      pays
      zipCode
      city
      prospectID
      code
      denomination
      type
      formeJuridique
      capital
      greffe
      rsc
      level
      createdAt
      updatedAt
      owner
    }
  }
`;
export const createProspectNote = /* GraphQL */ `
  mutation CreateProspectNote(
    $input: CreateProspectNoteInput!
    $condition: ModelProspectNoteConditionInput
  ) {
    createProspectNote(input: $input, condition: $condition) {
      id
      prospectID
      note
      createdAt
      updatedAt
      owner
    }
  }
`;
export const updateProspectNote = /* GraphQL */ `
  mutation UpdateProspectNote(
    $input: UpdateProspectNoteInput!
    $condition: ModelProspectNoteConditionInput
  ) {
    updateProspectNote(input: $input, condition: $condition) {
      id
      prospectID
      note
      createdAt
      updatedAt
      owner
    }
  }
`;
export const deleteProspectNote = /* GraphQL */ `
  mutation DeleteProspectNote(
    $input: DeleteProspectNoteInput!
    $condition: ModelProspectNoteConditionInput
  ) {
    deleteProspectNote(input: $input, condition: $condition) {
      id
      prospectID
      note
      createdAt
      updatedAt
      owner
    }
  }
`;
export const createProspectUser = /* GraphQL */ `
  mutation CreateProspectUser(
    $input: CreateProspectUserInput!
    $condition: ModelProspectUserConditionInput
  ) {
    createProspectUser(input: $input, condition: $condition) {
      id
      email
      phone
      name
      customerId
      createdAt
      updatedAt
      owner
    }
  }
`;
export const updateProspectUser = /* GraphQL */ `
  mutation UpdateProspectUser(
    $input: UpdateProspectUserInput!
    $condition: ModelProspectUserConditionInput
  ) {
    updateProspectUser(input: $input, condition: $condition) {
      id
      email
      phone
      name
      customerId
      createdAt
      updatedAt
      owner
    }
  }
`;
export const deleteProspectUser = /* GraphQL */ `
  mutation DeleteProspectUser(
    $input: DeleteProspectUserInput!
    $condition: ModelProspectUserConditionInput
  ) {
    deleteProspectUser(input: $input, condition: $condition) {
      id
      email
      phone
      name
      customerId
      createdAt
      updatedAt
      owner
    }
  }
`;
export const createLog = /* GraphQL */ `
  mutation CreateLog(
    $input: CreateLogInput!
    $condition: ModelLogConditionInput
  ) {
    createLog(input: $input, condition: $condition) {
      id
      message
      data
      createdAt
      updatedAt
      owner
    }
  }
`;
export const updateLog = /* GraphQL */ `
  mutation UpdateLog(
    $input: UpdateLogInput!
    $condition: ModelLogConditionInput
  ) {
    updateLog(input: $input, condition: $condition) {
      id
      message
      data
      createdAt
      updatedAt
      owner
    }
  }
`;
export const deleteLog = /* GraphQL */ `
  mutation DeleteLog(
    $input: DeleteLogInput!
    $condition: ModelLogConditionInput
  ) {
    deleteLog(input: $input, condition: $condition) {
      id
      message
      data
      createdAt
      updatedAt
      owner
    }
  }
`;
export const createConfigure = /* GraphQL */ `
  mutation CreateConfigure(
    $input: CreateConfigureInput!
    $condition: ModelConfigureConditionInput
  ) {
    createConfigure(input: $input, condition: $condition) {
      id
      title
      structures
      createdAt
      updatedAt
    }
  }
`;
export const updateConfigure = /* GraphQL */ `
  mutation UpdateConfigure(
    $input: UpdateConfigureInput!
    $condition: ModelConfigureConditionInput
  ) {
    updateConfigure(input: $input, condition: $condition) {
      id
      title
      structures
      createdAt
      updatedAt
    }
  }
`;
export const deleteConfigure = /* GraphQL */ `
  mutation DeleteConfigure(
    $input: DeleteConfigureInput!
    $condition: ModelConfigureConditionInput
  ) {
    deleteConfigure(input: $input, condition: $condition) {
      id
      title
      structures
      createdAt
      updatedAt
    }
  }
`;
export const createUser = /* GraphQL */ `
  mutation CreateUser(
    $input: CreateUserInput!
    $condition: ModelUserConditionInput
  ) {
    createUser(input: $input, condition: $condition) {
      id
      name
      phone
      role
      email
      password
      confirm
      token
      expiredDate
      statusCode
      createdAt
      updatedAt
      owner
    }
  }
`;
export const updateUser = /* GraphQL */ `
  mutation UpdateUser(
    $input: UpdateUserInput!
    $condition: ModelUserConditionInput
  ) {
    updateUser(input: $input, condition: $condition) {
      id
      name
      phone
      role
      email
      password
      confirm
      token
      expiredDate
      statusCode
      createdAt
      updatedAt
      owner
    }
  }
`;
export const deleteUser = /* GraphQL */ `
  mutation DeleteUser(
    $input: DeleteUserInput!
    $condition: ModelUserConditionInput
  ) {
    deleteUser(input: $input, condition: $condition) {
      id
      name
      phone
      role
      email
      password
      confirm
      token
      expiredDate
      statusCode
      createdAt
      updatedAt
      owner
    }
  }
`;
export const createTest = /* GraphQL */ `
  mutation CreateTest(
    $input: CreateTestInput!
    $condition: ModelTestConditionInput
  ) {
    createTest(input: $input, condition: $condition) {
      id
      name
      statusCode
      createdAt
      updatedAt
      owner
    }
  }
`;
export const updateTest = /* GraphQL */ `
  mutation UpdateTest(
    $input: UpdateTestInput!
    $condition: ModelTestConditionInput
  ) {
    updateTest(input: $input, condition: $condition) {
      id
      name
      statusCode
      createdAt
      updatedAt
      owner
    }
  }
`;
export const deleteTest = /* GraphQL */ `
  mutation DeleteTest(
    $input: DeleteTestInput!
    $condition: ModelTestConditionInput
  ) {
    deleteTest(input: $input, condition: $condition) {
      id
      name
      statusCode
      createdAt
      updatedAt
      owner
    }
  }
`;

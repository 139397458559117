export default [
  {
    path: '/pages/category',
    component: () => import('@/views/pages/category/IndexPage.vue'),
    children: [
      {
        path: 'list',
        name: 'list-categories',
        component: () => import('@/views/pages/category/ListCategoriesPage.vue'),
        meta: {
          pageTitle: 'pages.category.title',
          breadcrumb: [
            {
              text: 'pages.category.title',
              active: true,
            },
          ],
        },
      },
      {
        path: 'add-edit',
        name: 'add-edit-category',
        component: () => import('@/views/pages/category/AddEditCategoryPage.vue'),
        meta: {
          pageTitle: 'pages.category.title',
          breadcrumb: [
            {
              text: 'pages.category.title',
              active: true,
            },
          ],
        },
      },
    ],
  },
  {
    path: '/pages/static-page',
    component: () => import('@/views/pages/static-page/IndexPage.vue'),
    children: [
      {
        path: 'list',
        name: 'list-static-pages',
        component: () => import('@/views/pages/static-page/ListStaticPagesPage.vue'),
        meta: {
          pageTitle: 'pages.static_page.title',
          breadcrumb: [
            {
              text: 'pages.static_page.title',
              active: true,
            },
          ],
        },
      },
      {
        path: 'add-edit',
        name: 'add-edit-static-page',
        component: () => import('@/views/pages/static-page/AddEditStaticPagePage.vue'),
        meta: {
          pageTitle: 'pages.static_page.title',
          breadcrumb: [
            {
              text: 'pages.static_page.title',
              active: true,
            },
          ],
        },
      },
    ],
  },
  {
    path: '/pages/page',
    component: () => import('@/views/pages/page/IndexPage.vue'),
    children: [
      {
        path: 'list',
        name: 'list-pages',
        component: () => import('@/views/pages/page/ListPagePage.vue'),
        meta: {
          pageTitle: 'pages.page.title',
          breadcrumb: [
            {
              text: 'pages.page.title',
              active: true,
            },
          ],
        },
      },
      {
        path: 'add-edit',
        name: 'add-edit-page',
        component: () => import('@/views/pages/page/AddEditPagePage.vue'),
        meta: {
          pageTitle: 'pages.page.title',
          breadcrumb: [
            {
              text: 'pages.page.title',
              active: true,
            },
          ],
        },
      },
    ],
  },
  {
    path: '/pages/article',
    component: () => import('@/views/pages/article/IndexPage.vue'),
    children: [
      {
        path: 'list',
        name: 'list-articles',
        component: () => import('@/views/pages/article/ListArticlesPage.vue'),
        meta: {
          pageTitle: 'pages.article.title',
          breadcrumb: [
            {
              text: 'pages.article.title',
              active: true,
            },
          ],
        },
      },
      {
        path: 'add-edit',
        name: 'add-edit-article',
        component: () => import('@/views/pages/article/AddEditArticlePage.vue'),
        meta: {
          pageTitle: 'pages.article.title',
          breadcrumb: [
            {
              text: 'pages.article.title',
              active: true,
            },
          ],
        },
      },
    ],
  },
  {
    path: '/pages/comment',
    component: () => import('@/views/pages/comment/IndexPage.vue'),
    children: [
      {
        path: 'list',
        name: 'list-comments',
        component: () => import('@/views/pages/comment/ListCommentsPage.vue'),
        meta: {
          pageTitle: 'pages.comment.title',
          breadcrumb: [
            {
              text: 'pages.comment.title',
              active: true,
            },
          ],
        },
      },
      {
        path: 'add-edit',
        name: 'add-edit-comment',
        component: () => import('@/views/pages/comment/AddEditCommentPage.vue'),
        meta: {
          pageTitle: 'pages.comment.title',
          breadcrumb: [
            {
              text: 'pages.comment.title',
              active: true,
            },
          ],
        },
      },
    ],
  },
  {
    path: '/pages/faq',
    component: () => import('@/views/pages/faq/IndexPage.vue'),
    children: [
      {
        path: 'list',
        name: 'list-faqs',
        component: () => import('@/views/pages/faq/ListFAQsPage.vue'),
        meta: {
          pageTitle: 'pages.faq.title',
          breadcrumb: [
            {
              text: 'pages.faq.title',
              active: true,
            },
          ],
        },
      },
      {
        path: 'add-edit',
        name: 'add-edit-faq',
        component: () => import('@/views/pages/faq/AddEditFAQPage.vue'),
        meta: {
          pageTitle: 'pages.faq.title',
          breadcrumb: [
            {
              text: 'pages.faq.title',
              active: true,
            },
          ],
        },
      },
    ],
  },
   
  {
    path: '/pages/client',
    component: () => import('@/views/pages/client/IndexPage.vue'),
    children: [
      {
        path: 'list',
        name: 'list-clients',
        component: () => import('@/views/pages/client/ListClientsPage.vue'),
        meta: {
          pageTitle: 'pages.client.title',
          breadcrumb: [
            {
              text: 'pages.client.title',
              active: true,
            },
          ],
        },
      },
      {
        path: 'add-edit',
        name: 'add-edit-client',
        component: () => import('@/views/pages/client/AddEditClientPage.vue'),
        meta: {
          pageTitle: 'pages.client.title',
          breadcrumb: [
            {
              text: 'pages.client.title',
              active: true,
            },
          ],
        },
      },
    ],
  },
  {
    path: '/pages/category-document',
    component: () => import('@/views/pages/category-document/IndexPage.vue'),
    children: [
      {
        path: 'list',
        name: 'list-category-documents',
        component: () => import('@/views/pages/category-document/ListCategoryDocumentsPage.vue'),
        meta: {
          pageTitle: 'pages.category_document.title',
          breadcrumb: [
            {
              text: 'pages.category_document.title',
              active: true,
            },
          ],
        },
      },
      {
        path: 'add-edit',
        name: 'add-edit-category-document',
        component: () => import('@/views/pages/category-document/AddEditCategoryDocumentPage.vue'),
        meta: {
          pageTitle: 'pages.category_document.title',
          breadcrumb: [
            {
              text: 'pages.category_document.title',
              active: true,
            },
          ],
        },
      },
    ],
  },
  {
    path: '/pages/document',
    component: () => import('@/views/pages/document/IndexPage.vue'),
    children: [
      {
        path: 'list',
        name: 'list-documents',
        component: () => import('@/views/pages/document/ListDocumentsPage.vue'),
        meta: {
          pageTitle: 'pages.document.title',
          breadcrumb: [
            {
              text: 'pages.document.title',
              active: true,
            },
          ],
        },
      },
      {
        path: 'add-edit',
        name: 'add-edit-document',
        component: () => import('@/views/pages/document/AddEditDocumentPage.vue'),
        meta: {
          pageTitle: 'pages.document.title',
          breadcrumb: [
            {
              text: 'pages.document.title',
              active: true,
            },
          ],
        },
      },
    ],
  },
]

/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "eu-west-2",
    "aws_cloud_logic_custom": [
        {
            "name": "expressApi",
            "endpoint": "https://ktzajiz47k.execute-api.eu-west-2.amazonaws.com/prod",
            "region": "eu-west-2"
        }
    ],
    "aws_appsync_graphqlEndpoint": "https://kdlgk4c6pbfvrh7ynken7tanh4.appsync-api.eu-west-2.amazonaws.com/graphql",
    "aws_appsync_region": "eu-west-2",
    "aws_appsync_authenticationType": "API_KEY",
    "aws_appsync_apiKey": "da2-i2jrx6wv5vbize24xwlgbrahnu",
    "aws_cognito_identity_pool_id": "eu-west-2:1ec432b2-5347-407c-ae22-dd1ae9918156",
    "aws_cognito_region": "eu-west-2",
    "aws_user_pools_id": "eu-west-2_8QKqjpGTP",
    "aws_user_pools_web_client_id": "2s588p3957ifs7k76doc8rksdh",
    "oauth": {},
    "aws_cognito_username_attributes": [
        "EMAIL"
    ],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_user_files_s3_bucket": "lesfrenchiesd508c428e44148c7918755b8af62505f161300-prod",
    "aws_user_files_s3_bucket_region": "eu-west-2"
};


export default awsmobile;

export default [
  {
    path: '/pages/configure',
    component: () => import('@/views/pages/configure/IndexPage.vue'),
    children: [
      {
        path: 'list',
        name: 'list-configures',
        component: () => import('@/views/pages/configure/ListConfiguresPage.vue'),
        meta: {
          pageTitle: 'pages.configure.title',
          breadcrumb: [
            {
              text: 'pages.configure.title',
              active: true,
            },
          ],
        },
      },
      {
        path: 'add-edit',
        name: 'add-edit-configure',
        component: () => import('@/views/pages/configure/AddEditConfigurePage.vue'),
        meta: {
          pageTitle: 'pages.configure.title',
          breadcrumb: [
            {
              text: 'pages.configure.title',
              active: true,
            },
          ],
        },
      },
    ],
  },
  
]

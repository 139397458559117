import { Auth } from 'aws-amplify';
import commonServices from '@/services/commonServices';
import moment from 'moment';
// import * as mutations from "@/graphql/mutations";
// import * as queries from "@/graphql/queries";
// import constant from "@/services/enums";
export default {
  async signUp(inputUser) {
    try {
      return await Auth.signUp(inputUser)
    } catch (error) {
      return error;
    }
  },
  async signIn(email, password) {
    try {
      return await Auth.signIn(email, password);
    } catch (error) {
      return error;
    }
  },
  async confirmSignUp(email, code) {
    try {
      return await Auth.confirmSignUp(email, code);
    } catch (error) {
      return error;
    }
  },
  async resendSignUp(email) {
    try {
      return await Auth.resendSignUp(email);
    } catch (error) {
      return error;
    }
  },
  async updateUser(currentUser, inputUser) {
    const result = await Auth.updateUserAttributes(currentUser, inputUser)
    if (result.code === undefined) {
      await this.updateProfile(inputUser);
      return;
    }
    commonServices.showMsgCode(false, result.code)
  },
  async getProfile() {
    //    return await API.graphql({
    //         query: apiUser.getUser, 
    //         authMode: "AMAZON_COGNITO_USER_POOLS"
    //     });  
  },
  async updateProfile(inputUser) {
    const clone = { ...inputUser };
    // clone.id = store.state.auth.profile.sub;
    if (inputUser.birthdate != null) {
      const convert = moment(inputUser.birthdate, 'YYYY-MM-DD').format();
      clone.birthdate = convert
    }
    //    return await API.graphql({
    //         query: apiUser.updateUser,
    //         variables: {input: clone},
    //         authMode: "AMAZON_COGNITO_USER_POOLS"
    //     }, {
    //         Authorization: store.state.auth.token
    //     });  
  },

}

export default {
  userRoles: {
    admin: 'admin',
    public: 'public',
  },
  levelS3: {
    protected: 'protected',
    public: 'public',
  },
  uploadType: {
    page: 'page',
    blog: 'blog',
    post: 'post',
  },
  formatNumber: {
    numeral: true,
    numeralThousandsGroupStyle: 'thousand',
  },
}

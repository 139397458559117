import Vue from 'vue';
let loading = null;
export default {
  showLoading: () => {
    loading = Vue.$loading.show({
      canCancel: true,
      color: '#7367f0',
      loader: 'dots',
      isFullPage: true,
      zIndex: 11111,
    })
  },
  hideLoading: () => {
    // eslint-disable-next-line dot-notation
    if (loading && loading['hide']){
      loading.hide();
    }
  },
  showLoadingContainer(refContainer){
    loading = Vue.$loading.show({
      canCancel: true,
      color: '#7367f0',
      loader: 'dots',
      container: refContainer,
      zIndex: 11111,
    })
  },
}

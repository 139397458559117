/* eslint-disable import/no-extraneous-dependencies */
import slugify from 'slugify'; 
import Storage from '@aws-amplify/storage';
import awsconfig from '@/aws-exports';
import { v4 as uuidv4 } from 'uuid';
import moment from 'moment';
import $ from 'jquery';
import enums from '@/services/enumsServices';
import loadingService from '@/services/loadingService';
import cloudinaryService from '@/services/cloudinaryService'; 
export default { 
  uuid(){
    return uuidv4();
  },
  saveStorage(type, data){
    localStorage.setItem(type, data);
  },
  getStorage(type){
    return localStorage.getItem(type);
  },
  removeStorage(type){
    localStorage.removeItem(type);
  },
  getYesterday(now){
    const dayInLastWeek = moment(now).subtract('1', 'days');
    const first = moment(dayInLastWeek).startOf('day');
    const end = moment(dayInLastWeek).endOf('day');
    return [first.unix(), end.unix()];
  },
  getLastWeek(now){
    const dayInLastWeek = moment(now).subtract('1', 'weeks');
    const first = moment(dayInLastWeek).startOf('week');
    const end = moment(dayInLastWeek).endOf('week');
    return [first.unix(), end.unix()];
  },
  getLastMonth(now){
    const dayInLastMonth = moment(now).subtract(1, 'months'); 
    const first = moment(dayInLastMonth).startOf('month');
    const end = moment(dayInLastMonth).endOf('month');
    return [first.unix(), end.unix()];
  },
  getLastThreeMonths(now){
    const dayInLastMonth = moment(now).subtract(3, 'months'); 
    const first = moment(dayInLastMonth).startOf('month');
    const end = moment(now).subtract(2, 'months').endOf('month');
    return [first.unix(), end.unix()];
  },
  getLastLastYear(now){
    const dayInLastYear = moment(now).subtract(1, 'years'); 
    const first = moment(dayInLastYear).startOf('year');
    const end = moment(dayInLastYear).endOf('year');
    return [first.unix(), end.unix()];
  },
  async uploadImage(v, path, level) {
    const result = await Storage.put(`${path}/${uuidv4()}.jpg`, v, {
      contentType: 'image/jpeg',
      level,
    });
    if (result) {
      return `https://${awsconfig.aws_user_files_s3_bucket}.s3.${awsconfig.aws_appsync_region}.amazonaws.com/public/${result.key}`;
    } 
    return null;
  },
  async uploadFile(v, path, level) {
    const result = await Storage.put(`${path}/${uuidv4()}`, v, {
      contentType: v.type,
      level,
    });
    if (result) {
      return `https://${awsconfig.aws_user_files_s3_bucket}.s3.${awsconfig.aws_appsync_region}.amazonaws.com/public/${result.key}`;
    } 
    return null;
  },
  formatDatetime(value){
    return moment(value).format('DD/MM/YYYY hh:mm:ss');
  },
  formatUTCDatetime(value){
    return moment(value).utc().format('DD/MM/YYYY hh:mm:ss');
  },
  showError(that, code) {
    that.$bvToast.toast(that.$t(code), {
      title: that.$t('msg.notification'),
      variant: 'danger',
      solid: true,
    })
  },
  showSuccess(that, code) { 
    that.$bvToast.toast(that.$t(code), {
      title: that.$t('msg.notification'),
      variant: 'success',
      solid: true,
    })
  },
  slugify(data){
    return slugify(data, {
      replacement: '-', // replace spaces with replacement character, defaults to `-`
      remove: undefined, // remove characters that match regex, defaults to `undefined`
      lower: true, // convert to lower case, defaults to `false`
      strict: false, // strip special characters except replacement, defaults to `false`
     
    })
  },
  createCkEditorImageBrowse(){
    let ckInterval = null;
    ckInterval = setInterval(() => {
      const ckFinders = $('label.cke_dialog_ui_labeled_label');
      const buttonContainer = ckFinders.parent().parent().parent().find('.cke_dialog_ui_hbox_last')[0];
      let imageChoice = $('.cke_dialog_body').find('div.cke_dialog_title:contains("Image Properties")');
      if (imageChoice.length === 0) imageChoice = $('.cke_dialog_body').find('div.cke_dialog_title:contains("Propriétés de l")'); 
      if (imageChoice.length === 0) imageChoice = $('.cke_dialog_body').find('div.cke_dialog_title:contains("Thuộc tính của ảnh")'); 
      if (imageChoice && imageChoice.length > 0 && buttonContainer && $(buttonContainer).find('#insertFile').length === 0){
        $(buttonContainer).append('<input type="file" id="insertFile" style="margin-top: 10px; border-radius: 0px;" name="ckEditor"/>');
        const fileInput = $(buttonContainer).find('#insertFile');
        $(fileInput[0]).on('click', () => {
          $(fileInput[0]).val('');
        });
        $(fileInput[0]).on('change', async () => {
          try {
            const { files } = $('#insertFile')[0];
            loadingService.showLoading();
            const url = await this.uploadCkEditor(files[0])
            if (url){
              const urlInputContainer = ckFinders.parent();
              const urlInput = $(urlInputContainer).find('input.cke_dialog_ui_input_text');
              if (urlInput){
                $(urlInput[0]).val(url);
              }
            }
            loadingService.hideLoading();
          } catch (err){
            console.error(err);
          } finally {
            loadingService.hideLoading();
          }
        })
      } else if (imageChoice && imageChoice.length > 0 && buttonContainer && $(buttonContainer).find('#insertFile').length > 0){
        // clearInterval(ckInterval);
      }
    }, 1000)
    return ckInterval;
  },
  async uploadCkEditor($event) {
    return new Promise((resolve, reject) => {
      try {
        const reader = new FileReader();
        let url = '';
        reader.readAsDataURL($event);
        reader.onload = async () => {
          url = await this.uploadImage($event, 'ckeditor', enums.levelS3.public);
          resolve(url);
        }
      } catch (err){
        reject();
      }
    });
  },
  async uploadImageAndCloudinary(v, path, level, oldPublicId) {
    const result = await Storage.put(`${path}/${uuidv4()}.jpg`, v, {
      contentType: 'image/jpeg',
      level,
    });
    if (result) {
      const imageUrl = `https://${awsconfig.aws_user_files_s3_bucket}.s3.${awsconfig.aws_appsync_region}.amazonaws.com/public/${result.key}`;
      const cloudinaryQuery = await cloudinaryService.uploadImageCloudinary(imageUrl, oldPublicId);
      const cloudinaryData = cloudinaryQuery.data.uploadImageCloudinary;
      const cloudinaryObj = cloudinaryData || null;
      return { imageUrl, cloudinaryInfo: cloudinaryObj };
    } 
    return null;
  },
  getDefaultCkeditorConfig(content = null){
    return {
      extraPlugins: ['image2'],
      allowedContent: true,
      templates_replaceContent: true,
      contentsCss: '/ckeditor/themes/css/font.css',
      language: 'fr',
      // eslint-disable-next-line no-undef
      font_names: `Montserrat/Montserrat;Poppins/Poppins;${CKEDITOR.config.font_names}`,
      on: {
        instanceReady() {
          this.dataProcessor.htmlFilter.addRules({
            elements: {
              h2(element){
                // eslint-disable-next-line no-param-reassign
                element.attributes.class = 'h2Template';
              },
              h3(element){
                // eslint-disable-next-line no-param-reassign
                element.attributes.class = 'h3Template';
              },
            },
          });
          this.document.appendStyleSheet('/ckeditor/themes/css/bootstrap.min.css');
          this.document.appendStyleSheet('/ckeditor/themes/css/template.css');
          if (content){
            this.setData(content);
          }
        },
        change(){
          this.dataProcessor.htmlFilter.addRules({
            elements: {
              h2(element){
                // eslint-disable-next-line no-param-reassign
                element.attributes.class = 'h2Template';
              },
              h3(element){
                // eslint-disable-next-line no-param-reassign
                element.attributes.class = 'h3Template';
              },
            },
          });
          this.document.appendStyleSheet('/ckeditor/themes/css/bootstrap.min.css');
          this.document.appendStyleSheet('/ckeditor/themes/css/template.css');
        },
      },
    }
  },
  getListImagePropertyInText(content, propName){
    // eslint-disable-next-line prefer-const
    let result = [];
    let tempContent = content;
    if (!tempContent) return [];
    while (tempContent){
      let imgTabEndIndex = -1;
      let workingText = '';
      let imgTabStartIndex = tempContent.indexOf('<img');
      if (imgTabStartIndex === -1) imgTabStartIndex = tempContent.indexOf('< img');
      if (imgTabStartIndex === -1) tempContent = '';
      else {
        tempContent = tempContent.substring(imgTabStartIndex, tempContent.length);
        imgTabEndIndex = tempContent.indexOf('/>');
        if (imgTabEndIndex !== -1) imgTabEndIndex += 2;
        workingText = tempContent.substring(0, imgTabEndIndex);
        let arrSrc = [];
        arrSrc = workingText.split(propName);
        if (arrSrc.length > 1){
          let srcText = arrSrc[1];
          srcText = srcText.trim();
          srcText = srcText.replace('=\'', '');
          srcText = srcText.replace('="', '')
          srcText = srcText.trim();
          const index = srcText.indexOf(' ');
          srcText = srcText.substring(0, index);
          srcText = srcText.replace('\'', '')
          srcText = srcText.replace('"', '')
          srcText = srcText.trim();
          result.push(srcText);
        }
        // remove working test had done
        tempContent = tempContent.substring(imgTabEndIndex, tempContent.length);
      }
    }
    return result;
  },
  getLocalDate(dateStr){
    return moment(dateStr).format('YYYY-MM-DD');
  },
  getLocalDateTime(dateStr){
    return moment(dateStr).format('YYYY-MM-DD HH:mm:ss');
  },
  getUTCDateTimeStr(localTime){
    return localTime.toISOString()
  },
}
